import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

const defaultLanguage = 'zh-TW';
const SUPPORT_BROWSER_LANGUAGE = process.env.REACT_APP_SUPPORT_BROWSER_LANGUAGE.split(' ');

let browserlLang = navigator.language || navigator.userLanguage;

if (browserlLang === 'ja') {
	browserlLang = 'ja-JP';
}

i18n
	.use(
		resourcesToBackend((language, namespace, callback) => {
			import(/* webpackPrefetch: true */ /* webpackChunkName: "[request]" */ `../i18n/${language}/${namespace}.json`)
				.then(resources => {
					callback(null, resources);
				})
				.catch(error => {
					callback(error, null);
				});
		})
	)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		fallbackLng: defaultLanguage,
		lng: browserlLang,
		ns: ['main'],
		defaultNS: 'main',
		interpolation: {
			escapeValue: false,
		},
		supportedLngs: SUPPORT_BROWSER_LANGUAGE,
		react: {
			useSuspense: true,
		},
	});

export default i18n;
