import { createTheme } from '@material-ui/core/styles';

import hexRgba from 'hex-rgba';

export const color = {
	$1: '#B5D100',
	$2: '#9CC100',
	$3: '#2A394D',
	$4: '#364355',
	$5: '#253346',
	$6: '#000000',
	$7: '#FFFFFF',
	$8: '#EFEFEF',
	$9: '#E0E0E0',
	$10: '#BDBDBD',
	$11: '#989898',
	$12: '#757575',
	$13: '#4C4C4C',
	$14: '#212121',
	$15: '#21B5CD',
	$16: '#F1C036',
	$17: '#E95445',
	$18: '#DEA912',
	$19: hexRgba('#E0E0E0', 20),
	$20: hexRgba('#B5D100', 10),
	$21: '#F0F6CC',
	$22: '#FCFCFC',
	$23: '#F7FAE5',
};

export const theme = createTheme({
	palette: {
		primary: {
			main: color.$1,
			contrastText: color.$7,
		},
	},
	overrides: {
		MuiInputBase: {
			root: {
				lineHeight: 'inherit',
			},
		},
		MuiMenuItem: {
			root: {
				'&:hover': {
					color: color.$1,
					backgroundColor: color.$7,
				},
			},
		},
		MuiSwitch: {
			switchBase: {
				color: color.$9,
			},
			track: {
				backgroundColor: hexRgba(color.$9, 50),
				opacity: 'inherit',
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: color.$1,
				'& h4': {
					fontSize: '34px',
					color: color.$7,
					lineHeight: '52px',
				},
				'& h6': {
					fontSize: '16px',
					color: color.$7,
				},
			},
		},
		MuiPickersBasePicker: {
			pickerView: {
				height: 313,
			},
		},
		MuiPickersToolbarText: {
			toolbarTxt: {
				color: color.$7,
			},
		},
		MuiPickersDay: {
			daySelected: {
				backgroundColor: `${color.$1} !important`,
			},
		},
		MuiPickersSlideTransition: {
			transitionContainer: {
				'& p': {
					fontSize: '16px',
					lineHeight: '23px',
				},
			},
		},
	},
	button: {
		primaryBlue: {
			default: {
				fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
				fontStyle: 'normal',
				letterSpacing: '0px',
				lineHeight: '15px',
				color: color.$3,
				fontSize: 15,
				fontWeight: '400',
				height: '44px',
				backgroundColor: color.$7,
				border: `1px solid ${color.$3}`,
				borderRadius: '22px',
				textTransform: 'none',
			},
			hover: {
				color: color.$7,
				backgroundColor: color.$3,
			},
			disabled: {
				color: hexRgba(color.$3, 50),
				backgroundColor: color.$7,
				cursor: 'not-allowed',
			},
			spinner: {
				default: {
					color: color.$3,
					width: '20px !important',
					height: '20px !important',
				},
				hover: {
					color: color.$7,
				},
			},
			spinnerHover: {
				color: color.$7,
			},
		},
		primaryHighlight: {
			default: {
				height: '44px',
				color: color.$3,
				backgroundColor: color.$7,
				border: `2px solid ${color.$3}`,
				borderRadius: '22px',
				textTransform: 'none',
			},
			hover: {
				color: color.$7,
				backgroundColor: color.$3,
			},
			disabled: {
				color: hexRgba(color.$3, 50),
				backgroundColor: color.$7,
				cursor: 'not-allowed',
			},
			spinner: {
				default: {
					color: color.$3,
					width: '20px !important',
					height: '20px !important',
				},
				hover: {
					color: color.$7,
				},
			},
		},
		primaryGreen: {
			default: {
				fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
				fontStyle: 'normal',
				letterSpacing: '0px',
				lineHeight: '15px',
				fontSize: 15,
				fontWeight: '400',
				height: '44px',
				color: color.$7,
				backgroundColor: color.$1,
				borderRadius: '22px',
				textTransform: 'none',
			},
			hover: {
				backgroundColor: color.$2,
				cursor: 'pointer',
			},
			disabled: {
				color: hexRgba(color.$7, 50),
				backgroundColor: '#b5d100',
				cursor: 'not-allowed',
			},
			spinner: {
				color: color.$7,
				width: '20px !important',
				height: '20px !important',
			},
		},
		primaryRed: {
			default: {
				fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
				fontStyle: 'normal',
				letterSpacing: '0px',
				lineHeight: '15px',
				fontSize: 15,
				fontWeight: '400',
				height: '44px',
				color: color.$17,
				backgroundColor: color.$7,
				border: `1px solid ${color.$17}`,
				borderRadius: '22px',
				textTransform: 'none',
			},
			hover: {
				color: color.$7,
				backgroundColor: color.$17,
			},
			disabled: {
				color: hexRgba(color.$11, 50),
				border: `1px solid ${color.$9}`,
				backgroundColor: color.$7,
				cursor: 'not-allowed',
			},
		},
		primaryGrey: {
			default: {
				fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
				fontStyle: 'normal',
				letterSpacing: '0px',
				lineHeight: '15px',
				fontSize: 15,
				fontWeight: '400',
				height: '44px',
				color: color.$12,
				backgroundColor: color.$7,
				border: `1px solid ${color.$12}`,
				borderRadius: '22px',
				textTransform: 'none',
			},
			hover: {
				color: color.$7,
				backgroundColor: color.$12,
			},
			disabled: {
				color: hexRgba(color.$12, 50),
				border: `1px solid ${color.$9}`,
				backgroundColor: color.$7,
				cursor: 'not-allowed',
			},
		},
		IconPrimaryGrey: {
			default: {
				height: '44px',
				padding: '10px 20px 10px 16px',
				color: color.$11,
				backgroundColor: 'rgba(224, 224, 224, 0.2)',
				borderRadius: '22px',
				fontSize: '15px',
				lineHeight: 1,
				'& img': {
					display: 'inline-block',
					marginRight: '4px',
				},
			},
			hover: {
				backgroundColor: 'rgba(224, 224, 224, 0.5)',
			},
			disabled: {
				color: color.$10,
				border: `1px solid ${color.$10}`,
				backgroundColor: color.$7,
				cursor: 'not-allowed',
			},
		},
	},
	typography: {
		fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
		fontStyle: 'normal',
		letterSpacing: '0px',
		fontWeight: 400,
		color: color.$10,
		fontSize: 11,
		headline: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: '0px',
			lineHeight: '48px',
			color: color.$2,
			fontSize: 48,
			fontWeight: '200',
		},
		title: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: '0px',
			lineHeight: '28px',
			color: color.$3,
			fontSize: 28,
			fontWeight: '400',
		},
		display1: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: '0px',
			lineHeight: '24px',
			color: color.$12,
			fontSize: 24,
			fontWeight: '700',
		},
		display2: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: '0px',
			lineHeight: '20px',
			color: color.$3,
			fontSize: 20,
			fontWeight: '400',
		},
		display3: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: '0px',
			lineHeight: 1.4,
			color: color.$3,
			fontSize: 17,
			fontWeight: '400',
		},
		subheading: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: '0px',
			lineHeight: '15px',
			color: color.$3,
			fontSize: 15,
			fontWeight: '400',
		},
		subtitle1: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: '0px',
			lineHeight: 1.4,
			color: color.$3,
			fontSize: 15,
			fontWeight: '400',
		},
		tooltip: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: 'normal',
			color: color.$7,
			fontStretch: 'normal',
			fontSize: 13,
		},
		h1: {
			color: color.$2,
			fontSize: '48px',
		},
		h2: {
			color: color.$3,
			fontSize: '28px',
		},
		h3: {
			lineHeight: 'normal',
			color: color.$12,
			fontSize: '24px',
			fontWeight: 700,
		},
		h4: {
			color: color.$3,
			fontSize: '20px',
		},
		h5: {
			color: color.$3,
			fontSize: '17px',
		},
		h6: {
			color: color.$3,
			fontSize: '15px',
		},
		body1: {
			color: color.$13,
			fontSize: '13px',
		},
		body2: {
			color: color.$12,
			fontSize: '11px',
		},
	},
	input: {
		default: {
			wrapper: {
				border: 'solid 1px #bdbdbd',
				borderRadius: '22px',
				'&.valid, &.focus': {
					border: '1px solid  #b5d100',
				},
				'&.invalid': {
					border: '1px solid red',
				},
			},
			root: {},
			input: {
				backgroundColor: '#ffffff',
				color: color.$3,
				fontSize: 15,
			},
		},
	},
	dropdown: {
		default: {
			root: {
				position: 'relative',
				cursor: 'pointer',
				flex: 1,
			},
			toggle: {
				display: 'flex',
				flex: 1,
				alignItems: 'center',
				justifyContent: 'flex-start',
				padding: '0 0 0 24px',
				border: 'solid 1px #bdbdbd',
				borderRadius: '20px',
				backgroundColor: '#ffffff',
				height: '40px',
				fontSize: 15,
			},
			menu: {
				position: 'absolute',
				left: 0,
				right: 0,
				top: 44,
				zIndex: 999,
			},
		},
	},
	iconText: {
		primaryGreen: {
			default: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				cursor: 'pointer',
				color: color.$2,
			},
			hover: {
				color: hexRgba(color.$2, 50),
			},
		},
		primaryGrey: {
			default: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				cursor: 'pointer',
				color: color.$10,
			},
			hover: {
				color: hexRgba(color.$10, 50),
			},
		},
		primaryBlack: {
			default: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				cursor: 'pointer',
				color: color.$3,
			},
		},
	},
	link: {
		primaryGreen: {
			default: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				cursor: 'pointer',
				color: color.$2,
				textDecoration: 'underline',
				'&:hover': {
					color: hexRgba(color.$2, 50),
				},
			},
		},
		primaryGrey: {
			default: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				cursor: 'pointer',
				color: color.$10,
				textDecoration: 'underline',
				'&:hover': {
					color: hexRgba(color.$10, 50),
				},
			},
		},
	},
	badge: {
		dot: {
			top: '6px',
			height: '8px',
			padding: '6px',
			minWidth: '8px',
			right: '6px',
		},
		colorPrimary: {
			backgroundColor: color.$17,
			fontSize: 13,
		},
		colorSecondary: {
			backgroundColor: color.$16,
		},
	},
	chip: {
		root: {
			fontFamily: "'PingFang TC', 'Noto Sans TC', 'Helvetica Neue', Arial, sans-serif",
			fontStyle: 'normal',
			letterSpacing: '0px',
			color: color.$7,
			fontSize: 13,
			fontWeight: '400',
			height: '24px',
		},
		label: {
			padding: 0,
		},
		colorPrimary: {
			backgroundColor: color.$1,
			'&:hover, &:focus': {
				backgroundColor: color.$2,
			},
		},
		colorPrimaryEnabled: {
			backgroundColor: hexRgba(color.$1, 10),
			color: color.$2,
		},
		lightBlue: {
			color: color.$15,
			backgroundColor: hexRgba(color.$15, 10),
			'&:hover, &:focus': {
				backgroundColor: hexRgba(color.$15, 20),
			},
		},
		colorSecondary: {
			color: color.$7,
			backgroundColor: color.$16,
			'&:hover, &:focus': {
				backgroundColor: color.$18,
			},
		},
		black: {
			backgroundColor: color.$12,
			'&:hover, &:focus': {
				backgroundColor: color.$11,
			},
		},
	},
	toast: {
		anchorOriginBottomCenter: {
			bottom: '16px',
			position: 'absolute',
		},
	},
	toastContent: {
		root: {
			borderRadius: '50px',
			backgroundColor: color.$17,
		},
		second: {
			borderRadius: '50px',
			backgroundColor: color.$16,
		},
	},
	snackbar: {
		anchorOriginTopLeft: {
			top: '24px',
			left: '24px',
		},
		success: {
			backgroundColor: color.$1,
		},
		error: {
			backgroundColor: color.$17,
		},
		info: {
			backgroundColor: color.$1,
		},
		warning: {
			backgroundColor: color.$16,
		},
	},
	snackbarContent: {
		root: {
			borderRadius: '8px',
			paddingTop: '0px',
			paddingLeft: '24px',
			paddingBottom: '0px',
			minHeight: '48px',
			width: '100%',
		},
	},
	checkbox: {
		root: {
			padding: '5px',
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		colorPrimary: {
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 1024,
			md: 1440,
			lg: 1920,
			xl: 2560,
		},
	},
});
