import classnames from 'classnames';
import { useSelector } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import _findKey from 'lodash/findKey';
import _map from 'lodash/map';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../common/config';
import { color } from '../common/themes';
import Button from './Button';
import IconX from './IconX';
import ImageDisplay from './ImageDisplay';

import icSuper8 from '../assets/images/png/ic_super8_24@2x.png';

import icFbMessenger02 from '../assets/images/svg/ic-fbmessenger-02.svg';
import icLine from '../assets/images/svg/ic-line.svg';

import imgInstagram20 from '../assets/images/png/ic-instagram@2x.png';
import icMail from '../assets/images/svg/ic_mail_24.svg';
import icMobile from '../assets/images/svg/ic_mobile_24.svg';
import LivechatService from '../services/LivechatService';

import { getMessageSummary } from '../common/Util';

const useStyles = makeStyles(
	() => ({
		chat: (props: any) => ({
			display: 'flex',
			flexDirection: 'column',
			borderRadius: props.isMobile ? 'none' : '12px',
			backgroundColor: color.$7,
		}),
		header: (props: any) => ({
			position: 'relative',
			padding: 24,
			borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
			borderTopLeftRadius: props.isMobile ? 'none' : '12px',
			borderTopRightRadius: props.isMobile ? 'none' : '12px',
			height: 70,
		}),
		x: {
			position: 'absolute',
			top: 12,
			right: 12,
			cursor: 'pointer',
		},
		title: {
			fontSize: '17px',
			fontWeight: 500,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1',
			color: color.$13,
			marginBottom: 12,
		},
		description: {
			fontSize: '15px',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			color: color.$13,
		},
		content: (props: any) => ({
			minHeight: 382,
			padding: 24,
			backgroundColor: color.$7,
			height: props.isMobile ? 'calc(100vh - 195px)' : '100%',
			border: `1px solid ${color.$8}`,
		}),
		dialog: {
			borderRadius: '12px',
			boxShadow: '0 8px 36px 0 rgba(0, 0, 0, 0.06)',
			padding: 16,
		},
		text: {
			fontSize: '17px',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1',
			color: color.$13,
			marginBottom: 16,
		},
		fc: {
			display: 'flex',
			alignItems: 'center',
		},
		displayName: {
			fontSize: '15px',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1',
			color: color.$13,
		},
		text2: {
			fontSize: '13px',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1',
			color: color.$12,
			textAlign: 'center',
			marginBottom: 12,
		},
		communitys: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'& img': {
				width: 24,
				height: 24,
			},
			'& a + a': {
				marginLeft: 16,
			},
		},
		logo: {
			'& img': {
				borderRadius: '30px',
				boxShadow: '-5px 5px 8px 0 rgba(0, 0, 0, 0.2)',
				width: 60,
				height: 60,
			},
		},
		copyright: {
			fontSize: '13px',
			fontWeight: 500,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1.38',
			color: color.$12,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 30,
		},
		lastMessage: {
			color: color.$12,
			fontSize: 13,
			lineHeight: 1.31,
			marginBottom: 16,
		},
		twoLineWrap: {
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': '2',
			wordWrap: 'break-word',
		},
		borderRadius: {
			borderBottomLeftRadius: '12px',
			borderBottomRightRadius: '12px',
		},
	}),
	{
		name: 'Preview',
	}
);

function Preview({ onOpenChat, onClose, styleData, orgData, isGetLastMessage }: any) {
	const isMobile = useSelector((store: any) => store.socketio.isMobile);

	const classes = useStyles({ isMobile });

	const { t } = useTranslation();

	const [lastMessage, setLastMessage]: any = useState(null);
	const orgId = useSelector((store: any) => store.socketio.orgId);
	const customerToken = useSelector((store: any) => store.socketio.customerToken);

	const { livechatTextColor, livechatBackgroundColor, title, content, buttonTextColor, buttonColor, contacts, hiddenPoweredBy } =
		styleData || {};

	const iconMap: any = {
		line: icLine,
		facebook: icFbMessenger02,
		instagram: imgInstagram20,
		email: icMail,
		mobile: icMobile,
	};

	const getIconUrl = (data: string, key: string) => {
		let openUrl = data;
		switch (key) {
			case 'email':
				openUrl = `mailto:${data}`;
				break;
			case 'mobile':
				openUrl = `tel:${data}`;
				break;
			default:
		}

		return openUrl;
	};

	useEffect(() => {
		const getLastMessage = async () => {
			const response: any = await LivechatService.getMessages(orgId, customerToken, 0, 1);

			setLastMessage(response.results[0]);
		};
		if (customerToken && orgId && isGetLastMessage) {
			getLastMessage();
		}
	}, [customerToken, orgId, isGetLastMessage]);

	return (
		<div className={classes.chat}>
			<div className={classes.header} style={{ backgroundColor: livechatBackgroundColor }}>
				<IconX id="x" onClick={() => onClose()} className={classes.x} color={color.$7} hoverColor={color.$7} />
				<div className={classes.title} style={{ color: livechatTextColor }}>
					{title}
				</div>
				<div className={classnames(classes.description, classes.twoLineWrap)} style={{ color: livechatTextColor }}>
					{content}
				</div>
			</div>
			<div className={classnames(classes.content, { [classes.borderRadius]: hiddenPoweredBy })}>
				<div className={classes.dialog}>
					<div className={classes.text}>{t('conversation')}</div>
					<div className={classes.fc} style={{ marginBottom: 16 }}>
						<ImageDisplay
							style={{
								borderRadius: '50%',
								marginRight: 12,
							}}
							src={orgData.icon}
							width={44}
							height={44}
						/>
						<div>
							<div className={classes.displayName}>{orgData.displayName}</div>
						</div>
					</div>
					{customerToken && lastMessage && (
						<div className={classnames(classes.lastMessage, classes.twoLineWrap)}>
							{getMessageSummary(lastMessage, orgData.displayName)}
						</div>
					)}
					<Button
						style={{
							marginBottom: 24,
							width: '100%',
							backgroundColor: buttonColor,
							color: buttonTextColor,
						}}
						topic="primaryGreen"
						onClick={() => onOpenChat()}
					>
						{lastMessage ? t('continueConv') : t('startConv')}
					</Button>
					{!!_findKey(contacts, o => o) && (
						<>
							<div className={classes.text2}>{t('orSwitch')}</div>
							<div className={classes.communitys}>
								{_map(contacts, (value, key) => {
									return (
										value?.enabled &&
										(key === 'email' ? (
											<a key={`${value.data}`} target="_parent" href={getIconUrl(value.data, key)}>
												<img src={iconMap[key]} alt={key} />
											</a>
										) : key === 'mobile' ? (
											<a key={`${value.data}`} href={`#${key}`} onClick={() => window.open(getIconUrl(value.data, key))}>
												<img src={iconMap[key]} alt={key} />
											</a>
										) : (
											<a key={`${value.data}`} target="_blank" href={getIconUrl(value.data, key)} rel="noreferrer">
												<img src={iconMap[key]} alt={key} />
											</a>
										))
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
			{!hiddenPoweredBy && (
				<div className={classes.copyright}>
					<ImageDisplay
						style={{
							borderRadius: '50%',
							marginRight: 4,
						}}
						src={icSuper8}
						width={24}
						height={24}
					/>

					<a style={{ color: 'unset' }} href={config.SUPER8_URL} target="_blank" rel="noreferrer">
						{t('copyrignt')}
					</a>
				</div>
			)}
		</div>
	);
}

export default Preview;
