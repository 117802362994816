import { useState } from 'react';

function IconX({ className, width = 24, height = 24, id, color, hoverColor, viewBox, onClick }: any) {
	const [mainColor, setMainColor] = useState(color);
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width={width}
			height={height}
			viewBox={viewBox}
			onMouseEnter={() => setMainColor(hoverColor)}
			onMouseLeave={() => setMainColor(color)}
			onClick={() => onClick()}
		>
			<defs>
				<path
					id="icon-x"
					d="M17.743 16.543a.829.829 0 0 1 0 1.2.778.778 0 0 1-.6.257.778.778 0 0 1-.6-.257L12 13.2l-4.543 4.543a.778.778 0 0 1-.6.257.778.778 0 0 1-.6-.257.829.829 0 0 1 0-1.2L10.8 12 6.257 7.457a.829.829 0 0 1 0-1.2.829.829 0 0 1 1.2 0L12 10.8l4.543-4.543a.829.829 0 0 1 1.2 0 .829.829 0 0 1 0 1.2L13.2 12l4.543 4.543z"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h24v24H0z" />
				<mask id={id} fill="#FFF">
					<use xlinkHref="#icon-x" />
				</mask>
				<g fill={mainColor} mask={`url(#${id})`}>
					<path d="M0 0h24v24H0z" />
				</g>
			</g>
		</svg>
	);
}

export default IconX;
