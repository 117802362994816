import axios from 'axios';
import CryptoJS from 'crypto-js';
import linkify from 'linkify-it';
import _escape from 'lodash/escape';
import { v4 as uuidv4 } from 'uuid';
import i18n from './i18n';
import MESSAGE_TYPE from './messageType';
import store from './store';

const { getState } = store;

const linkifyInstance = linkify();

export const linkifyUrl = (rawText: any) => {
	const linkifyResult = linkifyInstance.match(rawText);

	const linkMap: any[] = [];

	const patternResult =
		linkifyResult &&
		linkifyResult.reduce((pre, current, idx) => {
			linkMap.push(`<a href='${linkifyResult[idx].url}' target='_blank'  class='textMessageLink'>${linkifyResult[idx].text}</a>`);
			return pre.replace(linkifyResult[idx].text, `%{${idx}}%`);
		}, rawText);
	const htmlText =
		linkMap.length > 0
			? linkMap.reduce((pre, current, idx) => {
					return pre.replace(`%{${idx}}%`, linkMap[idx]);
			  }, patternResult)
			: rawText;

	return { html: htmlText };
};

export const setParentCookie = (name: string, value: string, exdays: any = 365) => {
	window.parent.postMessage({ target: 'eightChat', command: 'notifySetCookie', options: { name, value, exdays } }, '*');
};

export const getParentCookie = (name: string) => {
	return window.parent.postMessage({ target: 'eightChat', command: 'notifyGetCookie', options: { name } }, '*');
};

export const deleteParentCookie = (name: string) => {
	window.parent.postMessage({ target: 'eightChat', command: 'notifyDeleteCookie', options: { name } }, '*');
};

export async function downloadImage(file: any) {
	const fileName = file.replace(/^.*[\\/]/, '');
	const response = await axios.get(`${file}?time=${new Date().getTime()}`, {
		responseType: 'blob',
	});
	const imageURL = URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.href = imageURL;
	link.download = fileName;
	link.click();
}

export const showText = (content: any, isNoBreakline: any = false) => {
	let text = '';
	const clean = _escape(content);
	const res = linkifyUrl(clean);
	if (res.html) {
		text = res.html;
	}
	if (text && text.length > 0) {
		if (isNoBreakline) {
			text = text.replace(/(\r\n|\n|\r)/gm, ' ');
		} else {
			text = text.replace(/(\r\n|\n|\r)/gm, '<br/>');
		}
	}

	return text;
};

export const permitMessageContentType = (contentType: string) => {
	switch (contentType) {
		default:
			return false;
		case MESSAGE_TYPE.IMAGE_SET:
		case MESSAGE_TYPE.AUDIO_TYPE:
		case MESSAGE_TYPE.VIDEO_TYPE:
		case MESSAGE_TYPE.FILE_TYPE:
		case MESSAGE_TYPE.IMAGE_TYPE:
		case MESSAGE_TYPE.TEMPLATE_TYPE:
		case MESSAGE_TYPE.TEXT_PLAIN_TYPE:
		case MESSAGE_TYPE.X_CUSTOMER_FORM:
		case MESSAGE_TYPE.BOT_TYPING:
			return true;
	}
};

export const getMessageSummary = (message: any, displayName: any) => {
	const { customerToken } = getState().socketio;

	const { contentType, sender } = message;
	const newSender = sender === customerToken ? i18n.t('chat.you') : displayName;
	switch (contentType) {
		default:
			return '';
		case MESSAGE_TYPE.FILE_TYPE:
			return newSender ? `${i18n.t('chat.sentFile', { displayName: newSender })}` : i18n.t('chat.sentFile');
		case MESSAGE_TYPE.IMAGE_SET:
		case MESSAGE_TYPE.IMAGE_TYPE:
			return newSender ? `${i18n.t('chat.sentPicture', { displayName: newSender })}` : i18n.t('chat.sentPicture');
		case MESSAGE_TYPE.AUDIO_TYPE:
			return newSender ? `${i18n.t('chat.sentAudio', { displayName: newSender })}` : i18n.t('chat.sentVideo');
		case MESSAGE_TYPE.VIDEO_TYPE:
			return newSender ? `${i18n.t('chat.sentVideo', { displayName: newSender })}` : i18n.t('chat.sentVideo');
		case MESSAGE_TYPE.TEMPLATE_TYPE:
			if (message.data.templateType === 'text') {
				return newSender ? `${newSender}:${message.data.content}` : message.data.content;
			}
			return newSender ? `${i18n.t('chat.sentTemplate', { displayName: newSender })}` : i18n.t('chat.sentTemplate');

		case MESSAGE_TYPE.TEXT_PLAIN_TYPE:
			return newSender ? `${newSender}:${message.data.content}` : message.data.content;
	}
};

export const getMessageObject = (sendData: any, type = 'text/plain') => {
	const { customerToken } = getState().socketio;

	return {
		sender: customerToken,
		senderType: 'Customer',
		data: sendData,
		contentType: type,
		receiptId: uuidv4(),
		createdAt: new Date().toISOString(),
		isPending: true,
	};
};

export const signPayload = (payload: any) => {
	const { customerToken } = getState().socketio;
	const nonce = new Date().getTime().toString();

	const baseString = CryptoJS.HmacSHA256(payload, `Super8_Customer/${customerToken}/${nonce}`).toString(CryptoJS.enc.Hex);

	const signature = CryptoJS.MD5(baseString).toString(CryptoJS.enc.Hex);

	const signedPayload = JSON.stringify({
		signature,
		nonce,
		data: payload,
	});
	return signedPayload;
};
