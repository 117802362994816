import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import queryString from 'query-string';
import config from './common/config';
import ImageDisplay from './components/ImageDisplay';

import SocketioLayer from './SocketioLayer';
import {
	getMessageObject,
	getMessageSummary,
	getParentCookie,
	permitMessageContentType,
	setParentCookie,
	signPayload,
} from './common/Util';
import MESSAGE_TYPE from './common/messageType';
import { color } from './common/themes';
import Chat from './components/Chat';
import ChatInput from './components/ChatInput';
import IconRoundX from './components/IconRoundX';
import MessageContent from './components/MessageContent';
import Preview from './components/Preview';
import { setCustomerToken, setIsMobile, setSocketioClientOrgId } from './features/socketioSlice';
import LivechatService from './services/LivechatService';

const useStyles = makeStyles(
	() => ({
		root: (props: any) => ({
			width: 360,
			maxWidth: 360,
			bottom: '100px',
			display: 'flex',
			position: 'absolute',
			transition: '.3s',
			maxHeight: `calc(100% - 110px - ${props?.styleData?.positionSettings?.bottom || 0}px)`,
			overflow: 'hidden',
			flexDirection: props?.styleData?.position === 'bottom-right' ? 'column' : 'inherit',
		}),
		rootMobile: {
			display: 'flex',
			flexDirection: 'column',
			position: 'absolute',
			transition: '.3s',
			width: '100%',
			inset: '0px',
			'-webkit-box-pack': 'start',
			justifyContent: 'flex-start',
			overflow: 'hidden',
		},
		iconRow: {
			position: 'absolute',
			bottom: '10px',
		},
		icon: {
			width: '60px',
			height: '60px',
			borderRadius: '30px',
			cursor: 'pointer',
			transition: 'transform 0.16s linear 0s, opacity 0.08s linear 0s',
			border: 'none',
			boxShadow: '-5px 5px 8px 0 rgb(0 0 0 / 20%)',
		},
		showInput: {
			backgroundColor: color.$7,
			border: `1px solid ${color.$8}`,
			borderRadius: '12px',
		},
		iconRoundX: {
			cursor: 'pointer',
			borderRadius: '12px',
			border: `1px solid ${color.$8}`,
		},
		notifyRow: {
			border: `1px solid ${color.$8}`,
			borderRadius: '12px',
			backgroundColor: color.$7,
			display: 'flex',
			alignItems: 'center',
			padding: '16px 12px',
			marginBottom: 16,
			fontSize: 15,
			color: color.$13,
		},
		notifyImg: {
			borderRadius: '30px',
			marginRight: 8,
		},
		fiveLineWrap: {
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': '5',
			wordWrap: 'break-word',
		},
		mobileNotifyRow: (props: any) => ({
			position: 'absolute',
			bottom: '100px',
			maxWidth: `calc(92% - ${props?.styleData?.positionSettings?.left || props?.styleData?.positionSettings?.right || 0}px)`,
			maxHeight: 'calc(100% - 100px)',
			overflow: 'auto',
		}),
		closeIconRow: {
			display: 'flex',
			justifyContent: 'flex-end',
			marginBottom: 16,
		},
		previewMessage: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
	}),
	{
		name: 'App',
	}
);

let timer: any = null;

const App = () => {
	const dispatch = useDispatch();

	const [isLoading, setIsloading] = useState(false);
	const [isInited, setIsInited] = useState(false);
	const [isBadgeShow, setIsBadgeShow] = useState(false);
	const [isShowPanel, setIsShowPanel] = useState(false);
	const [isStart, setIsStart] = useState(false);
	const [isOpenChat, setIsOpenChat] = useState(false);
	const [isGetLastMessage, setGetLastMessage] = useState(true);

	const { encryptedOrgId } = queryString.parse(window.location.search);
	const { autoOpenLiveChat } = queryString.parse(window.location.search);

	const [styleData, setStyleData]: any = useState(null);
	const [orgData, setOrgData]: any = useState(null);
	const [notifyMessage, setNotifyMessage]: any = useState(null);
	const chatRef = useRef<any>(null);
	const messageRef = useRef<any>(null);

	const socketClient = useSelector((store: any) => store.socketio.client);
	const socketClientReady = useSelector((store: any) => store.socketio.ready);
	const isMobile = useSelector((store: any) => store.socketio.isMobile);
	const customerToken = useSelector((store: any) => store.socketio.customerToken);

	const classes = useStyles({ styleData });

	const isMobileRef = useRef(null);
	isMobileRef.current = isMobile;

	const MA_SENDER = 'marketing_automation';

	const initCustomerToken = () => {
		const { customerToken: continuousCustomerToken } = queryString.parse(window.location.search);

		if (continuousCustomerToken) {
			dispatch(setCustomerToken(continuousCustomerToken));
		} else {
			getParentCookie(config.LOCAL_STORAGE_CUSTOMER_TOKEN);
		}
	};

	const onClearNotifyMessage = () => {
		if (isMobile) {
			window.parent.postMessage({ target: 'eightChat', command: 'notifyMobileHide' }, '*');
		} else {
			window.parent.postMessage({ target: 'eightChat', command: 'notifyDesktopHide' }, '*');
		}
	};

	const onReceiveMessage = (message: any) => {
		if (permitMessageContentType(message.contentType)) {
			setGetLastMessage(false);
			setTimeout(() => {
				setGetLastMessage(true);
			});

			if (!isShowPanel) {
				setIsBadgeShow(true);
				setNotifyMessage(message);

				setTimeout(() => {
					if (isMobile) {
						window.parent.postMessage(
							{
								target: 'eightChat',
								command: 'notifyMobileShow',
							},
							'*'
						);
					} else {
						window.parent.postMessage(
							{
								target: 'eightChat',
								command: 'notifyDesktopShow',
								options: { messageContentHeight: messageRef?.current?.offsetHeight, styleData },
							},
							'*'
						);
					}
				}, 500);

				clearTimeout(timer);
				timer = setTimeout(() => {
					setNotifyMessage(null);
					onClearNotifyMessage();
				}, 1000 * 20);
			}
		}

		if (message.contentType === MESSAGE_TYPE.SIGNAL) {
			setParentCookie(`${MESSAGE_TYPE.SIGNAL}_${customerToken}_${message.data.stepId}`, JSON.stringify(message.data), 30);
		}
	};

	const openIcon = () => {
		// because EventListener not get state value, so use Ref
		if (isMobileRef.current) {
			window.parent.postMessage({ target: 'eightChat', command: 'openImage' }, '*');
		} else {
			window.parent.postMessage({ target: 'eightChat', command: 'openIcon' }, '*');
		}

		setIsShowPanel(true);
	};

	useEffect(() => {
		if (isShowPanel) {
			window.parent.postMessage({ target: 'eightChat', command: 'badgeHide' }, '*');
			setIsBadgeShow(false);
			setIsStart(true);
		}
	}, [isShowPanel]);

	useEffect(() => {
		if (socketClient && socketClientReady) {
			socketClient.on('messageReceived', onReceiveMessage);
		}
		return () => {
			if (socketClient && socketClientReady) {
				socketClient.off('messageReceived', onReceiveMessage);
			}
		};
	}, [onReceiveMessage, socketClient, socketClientReady]);

	async function commandHandler(command: any, options: any) {
		if (!command) {
			return;
		}
		switch (command) {
			case 'createNewCustomer':
				dispatch(setCustomerToken(''));
				break;

			case 'getParentCookie':
				dispatch(setCustomerToken(options.customerToken));
				break;

			case 'notifyOpenIcon':
				openIcon();
				break;
			default:
		}
	}

	const getParentMessage = async (event: any) => {
		const eventData = event?.data;
		if (eventData?.target !== 'eightChat') {
			return;
		}

		await commandHandler(eventData.command, eventData.options);
	};

	useEffect(() => {
		if (autoOpenLiveChat && isInited) {
			openIcon();
		}
	}, [autoOpenLiveChat, isInited]);

	useEffect(() => {
		if (encryptedOrgId) {
			dispatch(setSocketioClientOrgId(encryptedOrgId));

			const getSettingData = async () => {
				const res: any = await LivechatService.getSettingData(encryptedOrgId);

				if (res) {
					if (res.position === 'bottom-right') {
						window.parent.postMessage({ target: 'eightChat', command: 'rightContent', options: { styleData: res } }, '*');
					} else {
						window.parent.postMessage({ target: 'eightChat', command: 'leftContent', options: { styleData: res } }, '*');
					}
					setStyleData(res);
				}
			};
			const getOrgData = async () => {
				const res: any = await LivechatService.getOrgData(encryptedOrgId);
				if (res) {
					setOrgData(res);

					if (res?.enableGA4) {
						setIsStart(true);
					}
				}
			};

			const init = async () => {
				setIsInited(false);
				setIsloading(true);
				initCustomerToken();
				dispatch(setIsMobile(/Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)));
				await getSettingData();
				await getOrgData();
				setIsloading(false);
				setIsInited(true);
			};
			init();

			window.addEventListener('message', getParentMessage);
		}
		return () => {
			window.addEventListener('message', getParentMessage);
		};
	}, [encryptedOrgId]);

	const onToggle = () => {
		if (isMobile) {
			if (!isShowPanel) {
				window.parent.postMessage({ target: 'eightChat', command: 'openImage' }, '*');
			} else {
				window.parent.postMessage({ target: 'eightChat', command: 'closeImage' }, '*');
			}
		} else if (!isShowPanel) {
			window.parent.postMessage({ target: 'eightChat', command: 'openIcon' }, '*');
		} else {
			window.parent.postMessage({ target: 'eightChat', command: 'closeIcon' }, '*');
		}

		setIsShowPanel(!isShowPanel);
	};

	const onCloseBadge = () => {
		setIsBadgeShow(false);
		onClearNotifyMessage();
	};

	const onClose = () => {
		if (isMobile) {
			window.parent.postMessage({ target: 'eightChat', command: 'closeImage' }, '*');
		} else {
			window.parent.postMessage({ target: 'eightChat', command: 'closeIcon' }, '*');
		}

		onClearNotifyMessage();
		setIsShowPanel(false);
	};

	const onOpenChat = () => {
		setIsOpenChat(true);
		socketClient.emit('follow');
		window.parent.postMessage({ target: 'eightChat', command: 'livechatClick' }, '*');
	};

	const notifyOpenChat = () => {
		if (isMobile) {
			window.parent.postMessage({ target: 'eightChat', command: 'openImage' }, '*');
		} else {
			window.parent.postMessage({ target: 'eightChat', command: 'openIcon' }, '*');
		}
		setIsBadgeShow(false);
		setIsShowPanel(true);
		onOpenChat();
	};

	const notifyAddMessage = (message: any) => {
		notifyOpenChat();
		setTimeout(() => {
			chatRef.current.notifyAddMessage(message);
		});
	};

	const notifySendMessage = (messageObject: any, eventType: any = 'message') => {
		let data = JSON.stringify(messageObject);

		// sign the payload
		data = signPayload(data);

		socketClient.emit(eventType, data);
	};

	const notifySubmitMessage = (sendData: any, type = MESSAGE_TYPE.TEXT_PLAIN_TYPE) => {
		const messageObject = getMessageObject(sendData, type);
		notifyAddMessage(messageObject);
		notifySendMessage(messageObject);
	};

	useEffect(() => {
		if (customerToken && isOpenChat && socketClient) {
			socketClient.emit('follow');
		}
	}, [customerToken, socketClient]);

	return (
		!isLoading &&
		styleData && (
			<>
				{isStart && <SocketioLayer />}
				<div
					style={!isMobile ? (styleData.position === 'bottom-right' ? { right: '14px' } : { left: '14px' }) : {}}
					className={isMobile ? classes.rootMobile : classes.root}
				>
					{isShowPanel &&
						(isOpenChat ? (
							<Chat
								ref={chatRef}
								styleData={styleData}
								orgData={orgData}
								onClose={() => onClose()}
								setIsOpenChat={setIsOpenChat}
							/>
						) : (
							<Preview
								styleData={styleData}
								isGetLastMessage={isGetLastMessage}
								orgData={orgData}
								onClose={() => onClose()}
								onOpenChat={onOpenChat}
							/>
						))}

					{isBadgeShow && notifyMessage && customerToken && (
						<div
							ref={messageRef}
							className={isMobile ? classes.mobileNotifyRow : ''}
							style={styleData.position === 'bottom-right' ? { right: '0px' } : { left: '0px' }}
						>
							<div className={classes.closeIconRow}>
								<IconRoundX className={classes.iconRoundX} onClick={() => onCloseBadge()} />
							</div>
							{notifyMessage.sender === MA_SENDER &&
							notifyMessage.contentType === MESSAGE_TYPE.TEMPLATE_TYPE &&
							notifyMessage.data.templateType !== 'text' ? (
								<div className={classes.previewMessage}>
									<MessageContent
										isPreview
										data={notifyMessage}
										submitMessage={notifySubmitMessage}
										isShowQuickReply={false}
										isBoxShadow={false}
									/>
								</div>
							) : (
								<div className={classes.notifyRow}>
									<ImageDisplay className={classes.notifyImg} src={styleData.logo} width={24} height={24} />
									<div className={classes.fiveLineWrap}>{getMessageSummary(notifyMessage, orgData.displayName)}</div>
								</div>
							)}
							{notifyMessage.sender !== MA_SENDER && (
								<ChatInput
									className={classes.showInput}
									addMessage={notifyAddMessage}
									submitMessage={notifySubmitMessage}
									sendMessage={notifySendMessage}
								/>
							)}
						</div>
					)}
				</div>

				{!(isMobile && isShowPanel) && (
					<div className={classes.iconRow} style={styleData.position === 'bottom-right' ? { right: '10px' } : { left: '10px' }}>
						<ImageDisplay
							className={classes.icon}
							src={styleData.logo}
							width={60}
							height={60}
							id="eightChatButton"
							onClick={() => onToggle()}
							badgeShow={isBadgeShow}
							badgeColor="primary"
						/>
					</div>
				)}
			</>
		)
	);
};

export default App;
