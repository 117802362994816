/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import classNames from 'classnames';
import MUITextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { color } from '../common/themes';

const useStyles = makeStyles(
	(theme: any) => ({
		body2: {
			...theme.typography.body2,
			marginRight: '8px',
			color: color.$10,
		},
		inputWrapper: {
			display: 'flex',
			flexDirection: 'row',
			borderRadius: '22px',
			alignItems: 'center',
			...theme.input.default.wrapper,
		},
		root: {
			overflowX: 'auto',
			overflowY: 'hidden',
			display: 'flex',
			alignItems: 'center',
			height: '42px',
			borderRadius: '22px',
		},
		multiline: {
			display: 'flex',
			alignItems: 'center',
			borderRadius: '22px',
			padding: '0px',
			marginLeft: 16,
		},
		input: {
			color: color.$3,
			WebkitTextFillColor: color.$3,
			padding: '12px',
			'&::placeholder': {
				color: color.$10,
				WebkitTextFillColor: color.$10,
				lineHeight: '25px',
				fontWeight: 'normal',
				opacity: 1,
			},
		},
		urlInput: {
			...theme.input.default.input,
			padding: '12px 0px 12px 16px',
			lineHeight: '21px',
			'&::placeholder': {
				transform: 'translateY(1%)',
			},
		},
		pathInput: {
			...theme.input.default.input,
			padding: '12px 16px',
			lineHeight: '21px',
			'&::placeholder': {
				transform: 'translateY(1%)',
			},
		},
		cardInput: {
			...theme.input.default.input,
			padding: '12px 16px 11px',
			lineHeight: '21px',
			'&::placeholder': {
				transform: 'translateY(1%)',
			},
		},
		defaultRoot: {
			...theme.input.default.root,
		},
		defaultInput: {
			...theme.input.default.input,
		},
		disableInput: {
			...theme.input.default.input,
			color: '#2a394d',
			WebkitTextFillColor: '#2a394d',
		},
		textFieldCardInput: {
			...theme.input.default.input,
			padding: '12px 16px',
		},
		error: {
			border: `1px solid ${color.$17}`,
		},
		errorText: {
			textAlign: 'end',
			...theme.typography.body1,
			color: color.$17,
			marginTop: '12px',
		},
		disabledInputWrapper: {
			border: 'solid 1px #e0e0e0',
		},
	}),
	{ name: 'Input' }
);

const Input = ({
	className,
	style,
	InputProps,
	topic,
	onChange,
	inputRef,
	multiline,
	disabled,
	enableUnderline = false,
	valid,
	placeholder,
	select,
	min,
	MenuItems,
	selectprops,
	isError,
	errorText,
	enableError,
	wordCount = 0,
	limit,
	inputProps,
	error,
	maxLength,
	...props
}: any) => {
	const classes: any = useStyles();
	const [isFocus, setIsFocus] = useState(false);
	const [inputValid, setInputValid] = useState('');

	const root = !multiline ? classes.root : classes.multiline;

	return (
		<>
			<MUITextField
				className={classNames(
					[
						className,
						classes.inputWrapper,
						...(inputValid !== undefined ? [valid ? 'valid' : 'invalid'] : []),
						...(error !== undefined ? [error ? 'invalid' : inputValid !== undefined ? 'valid' : undefined] : []),
					],
					{
						focus: isFocus,
						[classes.disabledInputWrapper]: disabled,
						[classes.error]: isError,
					}
				)}
				style={style}
				disabled={disabled}
				multiline={multiline}
				select={select}
				fullWidth
				inputRef={inputRef}
				selectprops={selectprops}
				InputProps={{
					disableUnderline: !enableUnderline,
					placeholder,
					classes: {
						root: classNames(root, classes[`${topic}Root`]),
						input: classNames([classes.input, classes[`${topic}Input`], disabled && classes.disableInput]),
					},
					onChange,
					inputProps: {
						min,
						maxLength,
						onFocus: () => {
							if (InputProps) {
								if (InputProps.onFocus) {
									InputProps.onFocus();
								}
							}
							setIsFocus(true);
							if (valid !== undefined) {
								setInputValid(valid ? 'valid' : 'invalid');
							} else {
								setIsFocus(false);
							}
						},
						onBlur: e => {
							if (InputProps) {
								if (InputProps.onBlur) {
									InputProps.onBlur(e);
								}
							}

							setIsFocus(false);
							setInputValid('');
						},
						...inputProps,
					},
					endAdornment: limit && (
						<InputAdornment className={classes.body2} position="end">
							<span>{`${wordCount}/${limit}`}</span>
						</InputAdornment>
					),
					...InputProps,
				}}
				{...props}
			>
				{MenuItems
					? MenuItems.map((option: any) => {
							return (
								<MenuItem key={option.value} value={option.value}>
									{option.text}
								</MenuItem>
							);
					  })
					: null}
			</MUITextField>
			{enableError && <div className={classes.errorText}>{errorText}</div>}
		</>
	);
};

export default Input;
