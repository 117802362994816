import { useState } from 'react';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(
	(theme: any) => ({
		root: {
			minWidth: 80,
		},
		buttonContent: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
		},
		isLoading: {
			cursor: 'not-allowed !important',
			'&:active': {
				pointerEvents: 'none',
			},
		},
		text: {
			padding: '14px 24px',
		},
		primaryBlue: {
			...theme.button.primaryBlue.default,
			'&:hover': {
				...theme.button.primaryBlue.hover,
			},
			'&.disabled': {
				...theme.button.primaryBlue.disabled,
				'&:hover': {
					...theme.button.primaryBlue.disabled,
				},
			},
		},
		primaryBlueSpinner: {
			...theme.button.primaryBlue.spinner.default,
			'&.hover': {
				...theme.button.primaryBlue.spinner.hover,
			},
		},
		primaryHighlight: {
			...theme.button.primaryHighlight.default,
			'&:hover': {
				...theme.button.primaryHighlight.hover,
			},
			'&.disabled': {
				...theme.button.primaryHighlight.disabled,
				'&:hover': {
					...theme.button.primaryHighlight.disabled,
				},
			},
		},
		primaryHighlightSpinner: {
			...theme.button.primaryHighlight.spinner.default,
			'&.hover': {
				...theme.button.primaryHighlight.spinner.hover,
			},
		},
		primaryGreen: {
			...theme.button.primaryGreen.default,
			'&:hover': {
				...theme.button.primaryGreen.hover,
			},
			'&.disabled': {
				...theme.button.primaryGreen.disabled,
				'&:hover': {
					...theme.button.primaryGreen.disabled,
				},
			},
		},
		primaryGreenSpinner: {
			...theme.button.primaryGreen.spinner,
		},
		primaryGrey: {
			...theme.button.primaryGrey.default,
			'&:hover': {
				...theme.button.primaryGrey.hover,
			},
			'&.disabled': {
				...theme.button.primaryGrey.disabled,
				'&:hover': {
					...theme.button.primaryGrey.disabled,
				},
			},
		},
		primaryRed: {
			...theme.button.primaryRed.default,
			'&:hover': {
				...theme.button.primaryRed.hover,
			},
			'&.disabled': {
				...theme.button.primaryRed.disabled,
				'&:hover': {
					...theme.button.primaryRed.disabled,
				},
			},
		},
		IconPrimaryGrey: {
			...theme.button.IconPrimaryGrey.default,
			'&:hover': {
				...theme.button.IconPrimaryGrey.hover,
			},
			'&.disabled': {
				...theme.button.IconPrimaryGrey.disabled,
				'&:hover': {
					...theme.button.IconPrimaryGrey.disabled,
				},
			},
		},
		autoFocus: {
			borderWidth: '2px',
		},
	}),
	{ name: 'Button' }
);

const Button = ({
	className,
	style,
	type,
	topic = 'primary',
	isDisable = false,
	children,
	width,
	height,
	isLoading,
	onClick,
	autoFocus,
	...others
}: any) => {
	const classes: any = useStyles();

	const [isHover, setIsHover] = useState(false);

	return (
		<MUIButton
			type={type}
			disabled={isDisable}
			style={{ width, height, ...style }}
			className={classNames([
				classes[topic],
				...(isDisable ? ['disabled'] : []),
				isLoading && classes.isLoading,
				className,
				autoFocus ? classes.autoFocus : null,
			])}
			classes={{
				root: classes.root,
				text: classes.text,
			}}
			onClick={onClick}
			onMouseOver={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setIsHover(false);
			}}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...others}
		>
			{isLoading ? (
				<CircularProgress className={classNames([classes[`${topic}Spinner`], ...(isHover ? ['hover'] : [])])} />
			) : (
				<div className={classes.buttonContent}>{children}</div>
			)}
		</MUIButton>
	);
};

export default Button;
