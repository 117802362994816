const messageType = {
	IMAGE_SET: 'application/x-image-set',
	IMAGE_TYPE: 'application/x-image',
	IMAGE_MAP_TYPE: 'application/x-imagemap',
	AUDIO_TYPE: 'application/x-audio',
	VIDEO_TYPE: 'application/x-video',
	FILE_TYPE: 'application/x-file',
	LOCATION_TYPE: 'application/x-location',
	TEMPLATE_TYPE: 'application/x-template',
	LINE_STICKER_TYPE: 'application/x-line-sticker',
	TEXT_PLAIN_TYPE: 'text/plain',
	SHARE_TYPE: 'application/x-share',
	FORM_TYPE: 'application/x-form',
	NOTIFY_EVENT: 'application/x-notify-event',
	STORY_MENTION: 'application/x-story_mention',
	LIKE_HEART: 'application/x-like-heart',
	DELETE: 'application/x-delete',
	CUSTOMER_SHARE: 'application/x-customer-share',
	UNSUPPORTED: 'application/x-unsupported',
	X_REACT: 'application/x-react',
	X_UNREACT: 'application/x-unreact',
	X_CUSTOMER_FORM: 'application/x-customer-form',
	BOT_TYPING: 'application/bot-typing',
	X_CUSTOMER_FORM_DONE: 'application/x-customer-form-done',
	SIGNAL: 'signal',
};

export default messageType;
