import { Component } from 'react';

import Badge from './Badge';

import defaultUser from '../assets/images/svg/default-customer-light.svg';

class ImageDisplay extends Component<any> {
	image = new Image();

	constructor(props: any) {
		super(props);
		this.state = {
			src: props.src || defaultUser,
		};
	}

	componentDidMount() {
		const { src }: any = this.props;
		const useSrc = src || defaultUser;
		this.image.onload = () => {
			this.setState({ src: useSrc });
		};
		this.image.onerror = () => {
			this.setState({ src: defaultUser });
		};
		this.image.src = useSrc;
	}

	componentDidUpdate(prevProps: any) {
		const { src }: any = this.props;
		if (prevProps.src !== src) {
			const useSrc = src || defaultUser;

			this.image.onload = () => {
				this.setState({ src: useSrc });
			};
			this.image.onerror = () => {
				this.setState({ src: defaultUser });
			};
			this.image.src = useSrc;
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
		this.setState = (state, callback) => {};
	}

	renderImage() {
		const { alt, className, style, width, height, onClick, id }: any = this.props;
		const { src }: any = this.state;

		return (
			<Badge className={this.props.badgeClass} badgeColor={this.props.badgeColor} invisible={!this.props.badgeShow} variant="dot">
				<img
					role="presentation"
					alt={alt}
					style={style}
					className={className}
					onClick={onClick}
					src={src}
					id={id}
					width={width}
					height={height}
				/>
			</Badge>
		);
	}

	render() {
		return this.renderImage();
	}
}

export default ImageDisplay;
