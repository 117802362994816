import { createSlice } from '@reduxjs/toolkit';

export const socketioSlice = createSlice({
	name: 'socketio',
	initialState: {
		client: null,
		ready: false,
		orgId: '',
		isMobile: false,
		customerToken: '',
	},
	reducers: {
		setSocketioClient: (state, action) => {
			return { ...state, client: action.payload };
		},
		setSocketioClientReady: (state, action) => {
			return { ...state, ready: action.payload };
		},
		setSocketioClientOrgId: (state, action) => {
			return { ...state, orgId: action.payload };
		},
		setIsMobile: (state, action) => {
			return { ...state, isMobile: action.payload };
		},
		setCustomerToken: (state, action) => {
			return { ...state, customerToken: action.payload };
		},
	},
});

export const {
	setSocketioClient,
	setSocketioClientReady,
	setSocketioClientOrgId,
	setIsMobile,
	setCustomerToken,
} = socketioSlice.actions;

export default socketioSlice.reducer;
