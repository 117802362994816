import { configureStore } from '@reduxjs/toolkit';
import socketioReducer from '../features/socketioSlice';

export default configureStore({
	reducer: {
		socketio: socketioReducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
