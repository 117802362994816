const IconBack = ({ onClick, className, id, width = 24, height = 24, color }: any) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			className={className}
			onClick={onClick}
		>
			<mask id={id} maskUnits="userSpaceOnUse" x="8" y="6" width="7" height="12">
				<path
					d="m8.262 11.4 5.25-5.143A.802.802 0 0 1 14.125 6c.262 0 .438.086.613.257.35.343.35.857 0 1.2L10.1 12l4.638 4.543c.35.343.35.857 0 1.2a.858.858 0 0 1-1.226 0L8.262 12.6a.817.817 0 0 1 0-1.2z"
					fill="#fff"
				/>
			</mask>
			<g mask={`url(#${id})`}>
				<path fill={color} d="M24 24H0V0h24z" />
			</g>
		</svg>
	);
};

export default IconBack;
