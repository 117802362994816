import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import config from './common/config';
import LivechatService from './services/LivechatService';
import { setSocketioClient, setSocketioClientReady, setCustomerToken } from './features/socketioSlice';
import { setParentCookie, deleteParentCookie } from './common/Util';
import i18n from './common/i18n';

export default function SocketioLayer() {
	const dispatch = useDispatch();

	const [isErrorDomain, setIsErrorDomain] = useState(false);

	const orgId = useSelector((store: any) => store.socketio.orgId);
	const client = useSelector((store: any) => store.socketio.client);
	const customerToken = useSelector((store: any) => store.socketio.customerToken);

	const generateToken = async () => {
		const response = await LivechatService.generateToken(orgId);
		if (!response?.customerToken) {
			// TODO: handle failed case
		}
		dispatch(setCustomerToken(response.customerToken));
	};

	const initClient = () => {
		const socketClient: any = io(config.SOCKET_SERVER_URL, {
			forceNew: true,
			path: '/livechat/',
			auth: { customerToken, encryptedOrgId: orgId },
			query: { locale: i18n.language },
			transports: ['websocket'],
			autoConnect: false,
		});

		socketClient.on('ready', (token: any) => {
			socketClient.token = token;
			console.log('Live Chat connected', token);

			setParentCookie(config.LOCAL_STORAGE_CUSTOMER_TOKEN, customerToken);
			dispatch(setSocketioClientReady(true));

			// 通知外層已經建立好連結，代表livechat OK
			window.parent.postMessage(
				{
					target: 'eightChat',
					command: 'connectReady',
					options: { status: true },
				},
				'*'
			);
		});
		socketClient.on('disconnect', () => {
			console.log('Live Chat disconnected');
			dispatch(setSocketioClientReady(false));

			// 通知外層已經建立好連結，代表livechat OK
			window.parent.postMessage(
				{
					target: 'eightChat',
					command: 'connectReady',
					options: { status: false },
				},
				'*'
			);
		});

		socketClient.io.on('reconnect', () => {});

		socketClient.on('errorReceived', (data: any) => {
			console.log(`errorReceived:${data.message}`);
			if (data.code === 'live.chat.domainNotAccept') {
				setIsErrorDomain(true);
			}

			if (data.code === 'live.chat.invalidCustomer') {
				deleteParentCookie(config.LOCAL_STORAGE_CUSTOMER_TOKEN);
				dispatch(setSocketioClient(null));
			}
		});

		socketClient.connect();
		dispatch(setSocketioClient(socketClient));
	};

	useEffect(() => {
		if (!client && customerToken && orgId && !isErrorDomain) {
			initClient();
		}

		return () => {
			if (client) {
				client.off();
				client.io.off();
				dispatch(setSocketioClient(null));
				dispatch(setSocketioClientReady(false));
			}
		};
	}, [client, orgId, isErrorDomain, customerToken]);

	useEffect(() => {
		if (!customerToken && orgId) {
			generateToken();
		}
	}, [orgId, customerToken]);

	return null;
}
