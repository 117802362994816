import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIBadge from '@material-ui/core/Badge';
import { color } from '../common/themes';

const useStyles = (theme: any) => ({
	badge: {
		color: color.$7,
		top: '50%',
		minWidth: '30px',
		height: '18px',
		// The border color match the background color.
		borderRadius: '9.5px',
	},
	standard: {
		'& > .MuiBadge-anchorOriginTopRightRectangle': {
			padding: '0 8px',
		},
	},
	dot: theme.badge.dot,
	colorPrimary: theme.badge.colorPrimary,
	colorSecondary: theme.badge.colorSecondary,
});

// eslint-disable-next-line react/prefer-stateless-function
class Badge extends Component<any> {
	render() {
		const { classes, badgeContent, badgeColor, invisible, children, className } = this.props;
		return (
			<MUIBadge
				overlap="rectangular"
				className={badgeContent ? classes.standard : className}
				color={badgeColor}
				variant={badgeContent ? 'standard' : 'dot'}
				badgeContent={badgeContent}
				invisible={invisible}
			>
				{children}
			</MUIBadge>
		);
	}
}

export default withStyles(useStyles, { name: 'MuiBadge' })(Badge);
