import { useState } from 'react';
import { color } from '../common/themes';

const IconEmoji = ({ onClick, className, id, width = 24, height = 24, iconColor = color.$12 }: any) => {
	const [isHover, setIsHover] = useState(false);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			className={className}
			style={{ cursor: 'pointer' }}
			onMouseEnter={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setIsHover(false);
			}}
			onClick={onClick}
		>
			<defs>
				<path
					id="emoji"
					d="M12 3a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm0 1.636a7.364 7.364 0 1 0 0 14.728 7.364 7.364 0 0 0 0-14.728zm2.867 9.064a.818.818 0 0 1 .437 1.072c-.588 1.399-1.738 2.137-3.304 2.137-1.578 0-2.709-.754-3.224-2.176a.818.818 0 0 1 1.539-.557c.275.76.78 1.097 1.685 1.097.917 0 1.466-.353 1.795-1.135a.818.818 0 0 1 1.072-.438zm-5.73-4.973a1.227 1.227 0 1 1 0 2.455 1.227 1.227 0 0 1 0-2.455zm5.727 0a1.227 1.227 0 1 1 0 2.455 1.227 1.227 0 0 1 0-2.455z"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<mask id={`emojiMask${id}`} fill="#fff">
					<use xlinkHref="#emoji" />
				</mask>
				<g fill={isHover ? color.$1 : iconColor} mask={`url(#emojiMask${id})`}>
					<path d="M0 0h24v24H0z" />
				</g>
			</g>
		</svg>
	);
};

export default IconEmoji;
