import axios from 'axios';
import config from './config';

export const nextAPI = axios.create({ baseURL: config.NEXT_API_URL });

nextAPI.interceptors.response.use(
	response => response.data,
	error => Promise.reject(error)
);

export const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
	response => response.data,
	error => Promise.reject(error)
);

export default nextAPI;
