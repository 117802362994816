import { useState } from 'react';
import { color } from '../common/themes';

const IconSend = ({ onClick, className, id, width = 24, height = 24, iconColor = color.$12, disabled }: any) => {
	const [isHover, setIsHover] = useState(false);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			className={className}
			onMouseEnter={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setIsHover(false);
			}}
			onClick={() => {
				if (disabled) {
					return;
				}
				onClick();
			}}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21 4.814c0-.081 0-.162-.082-.244V4.49c0-.082-.082-.163-.163-.245-.041-.04-.082-.06-.123-.081-.041-.02-.082-.04-.123-.082h-.082c-.081 0-.163 0-.245-.081v.081c-.082 0-.164 0-.246.082L3.573 9.864c-.328.082-.573.326-.573.733 0 .326.164.652.49.815l7.037 3.095 3.11 7.004c.163.326.409.489.736.489a.785.785 0 0 0 .736-.57l5.727-16.29C21 5.06 21 4.977 21 4.814zm-3.927 2.037-6.055 6.027-4.99-2.2 11.045-3.827zm-4.91 7.167 2.21 4.968 3.845-10.995-6.054 6.027z"
				fill="#000"
			/>
			<mask id={id} maskUnits="userSpaceOnUse" x="3" y="4" width="18" height="18">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21 4.814c0-.081 0-.162-.082-.244V4.49c0-.082-.082-.163-.163-.245-.041-.04-.082-.06-.123-.081-.041-.02-.082-.04-.123-.082h-.082c-.081 0-.163 0-.245-.081v.081c-.082 0-.164 0-.246.082L3.573 9.864c-.328.082-.573.326-.573.733 0 .326.164.652.49.815l7.037 3.095 3.11 7.004c.163.326.409.489.736.489a.785.785 0 0 0 .736-.57l5.727-16.29C21 5.06 21 4.977 21 4.814zm-3.927 2.037-6.055 6.027-4.99-2.2 11.045-3.827zm-4.91 7.167 2.21 4.968 3.845-10.995-6.054 6.027z"
					fill="#fff"
				/>
			</mask>
			<g mask={`url(#${id})`} fill={disabled ? color.$8 : isHover ? color.$1 : iconColor}>
				<path d="M0 0h24v24H0z" />
			</g>
		</svg>
	);
};

export default IconSend;
