import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { color } from '../common/themes';

const useStyles = {
	white: {
		'& svg circle': {
			color: `${color.$7}`,
		},
	},
	grey: {
		'& svg circle': {
			color: `${color.$10}`,
		},
	},
	blue: {
		'& svg circle': {
			color: `${color.$3}`,
		},
	},
};

const Circular = ({ classes, topic = 'white', ...spreadProps }: any) => {
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <CircularProgress classes={{ root: classes[`${topic}`] }} {...spreadProps} />;
};

export default withStyles(useStyles)(Circular);
