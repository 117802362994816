import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import hexRgba from 'hex-rgba';
import { color } from '../common/themes';
import ImageDisplay from './ImageDisplay';
import chatbotIcon from '../assets/images/png/ic_chatbot.png';
import typingIcon from '../assets/images/gif/bot_typing.gif';

const useStyles = makeStyles(
	() => ({
		fromOrg: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			padding: '12px 16px',
		},
		botIcon: {
			width: 44,
			height: 44,
			borderRadius: '50%',
			backgroundColor: hexRgba(color.$3, 60),
			position: 'absolute',
			zIndex: 1,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'& > img': {
				width: 24,
				height: 24,
			},
		},
		botText: {
			borderRadius: 12,
			backgroundColor: color.$8,
			fontSize: 15,
			color: color.$13,
			padding: '8px 12px',
			maxWidth: 188,
			wordWrap: 'break-word',
			wordBreak: 'break-word',
			display: 'flex',
			alignItems: 'center',
			'& > img': {
				marginRight: 10,
				width: 36,
				height: 20,
			},
		},
		imgDisplay: {
			position: 'relative',
		},
	}),
	{
		name: 'Chat',
	}
);

const BotTyping = ({ orgData, isMobile }: any) => {
	const { t } = useTranslation();
	const classes = useStyles({ isMobile });

	return (
		<div className={classes.fromOrg} key="aibot">
			<div className={classes.imgDisplay}>
				<div className={classes.botIcon}>
					<img src={chatbotIcon} alt="chatbot" />
				</div>
				<ImageDisplay
					alt="aibot"
					style={{
						borderRadius: '50%',
					}}
					src={orgData.icon}
					width={44}
					height={44}
				/>
			</div>
			<div style={{ margin: '0px 12px', width: 'calc(100% - 88px)' }}>
				<div className={classes.botText}>
					<img src={typingIcon} alt="botTyping" />
					{t('botTyping')}
				</div>
			</div>
		</div>
	);
};

export default BotTyping;
