import makeStyles from '@material-ui/core/styles/makeStyles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TextField from './TextField';
import Button from './Button';
import { color } from '../common/themes';
import { showText } from '../common/Util';
import LivechatService from '../services/LivechatService';

const useStyles = makeStyles(
	() => ({
		customerFormTitle: {
			fontSize: 15,
			color: color.$13,
			marginBottom: 24,
		},
		customerForm: {
			backgroundColor: color.$7,
			wordWrap: 'break-word',
			padding: '16px',
			width: 204,
			boxShadow: '0 8px 36px 0 rgba(0, 0, 0, 0.06)',
			borderRadius: 12,
		},
		customerFormRow: {
			marginBottom: 12,
		},
		customerFormButton: {
			width: '100%',
		},
	}),
	{
		name: 'CustomerForm',
	}
);

const CustomerForm = ({ data, submitCustomerFormMessage }: any) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(false);

	const orgId = useSelector((store: any) => store.socketio.orgId);
	const customerToken = useSelector((store: any) => store.socketio.customerToken);

	const { emailReply, isEnabledEmailReply, isEnabledPhoneReply } = data.data;

	const onChangeName = (e: any) => {
		setName(e.target.value);
	};

	const emailValid = (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

	const onChangeEmail = (e: any) => {
		const { value } = e.target;
		setEmail(value);
		setIsEmailValid(emailValid(value));
	};

	const onChangePhone = (e: any) => {
		const { value } = e.target;
		setPhone(value);
		// setIsPhoneValid(phoneValid(value));
	};

	const submitForm = async () => {
		await LivechatService.submitCustomerForm(orgId, customerToken, name, email, phone);

		const newData = JSON.parse(JSON.stringify(data));
		newData.contentType = 'application/x-customer-form-done';
		newData.data.displayName = name;
		newData.data.email = email;
		newData.data.phone = phone;

		setName('');
		setEmail('');
		setPhone('');
		submitCustomerFormMessage(newData);
	};

	return (
		<div className={classes.customerForm}>
			<div
				dangerouslySetInnerHTML={{
					__html: showText(emailReply),
				}}
				className={classes.customerFormTitle}
			/>
			<TextField
				isValid={name}
				maxLength="50"
				className={classes.customerFormRow}
				value={name}
				onChange={onChangeName}
				placeholder={t('displayName')}
			/>
			{isEnabledEmailReply && (
				<TextField
					isValid={isEmailValid}
					maxLength="100"
					className={classes.customerFormRow}
					value={email}
					onChange={onChangeEmail}
					placeholder={t('email')}
					type="email"
				/>
			)}
			{isEnabledPhoneReply && (
				<TextField
					isValid={phone}
					maxLength="100"
					className={classes.customerFormRow}
					value={phone}
					onChange={onChangePhone}
					placeholder={t('phone')}
				/>
			)}
			<Button
				topic="primaryGreen"
				onClick={() => {
					submitForm();
				}}
				disabled={!name || (isEnabledEmailReply && (!email || !isEmailValid)) || (isEnabledPhoneReply && !phone)}
				className={classes.customerFormButton}
			>
				{t('submit')}
			</Button>
		</div>
	);
};

export default CustomerForm;
