function IconRoundX({ className, onClick }: any) {
	return (
		<>
			<svg
				className={className}
				onClick={() => onClick()}
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="24" height="24" rx="12" fill="white" />
				<path
					d="M17.7429 16.5429C18.0857 16.8857 18.0857 17.4 17.7429 17.7429C17.5714 17.9143 17.4 18 17.1429 18C16.8857 18 16.7143 17.9143 16.5429 17.7429L12 13.2L7.45714 17.7429C7.28571 17.9143 7.11429 18 6.85714 18C6.6 18 6.42857 17.9143 6.25714 17.7429C5.91429 17.4 5.91429 16.8857 6.25714 16.5429L10.8 12L6.25714 7.45714C5.91429 7.11429 5.91429 6.6 6.25714 6.25714C6.6 5.91429 7.11429 5.91429 7.45714 6.25714L12 10.8L16.5429 6.25714C16.8857 5.91429 17.4 5.91429 17.7429 6.25714C18.0857 6.6 18.0857 7.11429 17.7429 7.45714L13.2 12L17.7429 16.5429Z"
					fill="#4C4C4C"
				/>
			</svg>
		</>
	);
}

export default IconRoundX;
