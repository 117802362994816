import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { color } from '../common/themes';
import EmojiPicker from './EmojiPicker';
import TextField from './TextField';

import IconSend from './IconSend';

import AutoConnectBot from './AutoConnectBot';
import UploadImage from './UploadImage';

const useStyles = makeStyles(
	() => ({
		root: {
			display: 'flex',
			padding: '13px 6px 13px 0',
			borderTop: '1px solid rgba(0, 0, 0, 0.1)',
			border: `1px solid ${color.$8}`,
			borderBottomLeftRadius: '12px',
			borderBottomRightRadius: '12px',
		},
		endAdornment: {
			paddingTop: '4px',
			paddingRight: '15px',
		},
		fileGroup: {
			display: 'flex',
			alignItems: 'center',
			margin: '0px 10px',
		},
		fileGroup2: {
			display: 'flex',
			alignItems: 'center',
			marginRight: '10px',
		},
		fileStyle: {
			paddingTop: 4,
		},
		input: {
			'& > div > textarea': {
				paddingRight: 16,
			},
		},
	}),
	{
		name: 'ChatInput',
	}
);

const ChatInput = ({ botSetting, sendBotMessage, submitMessage, addMessage, sendMessage, className }: any) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const chartInputRef: any = useRef(null);

	const [chatInput, setChatInput] = useState('');

	const textChangeHandler = (event: any) => {
		setChatInput(event.target.value);
		chartInputRef.current.focus();
	};

	const onEmojiClick = (emoji: any) => {
		const startPos = chartInputRef.current.selectionStart;
		const endPos = chartInputRef.current.selectionEnd;

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const newValue =
			chartInputRef.current.value.substring(0, startPos) +
			emoji +
			chartInputRef.current.value.substring(endPos, chartInputRef.current.value.length);

		setChatInput(newValue);

		setTimeout(() => {
			chartInputRef.current.focus();
			chartInputRef.current.selectionStart = startPos + 2;
			chartInputRef.current.selectionEnd = endPos + 2;
		});
	};

	const submitHandler = () => {
		submitMessage({ content: chatInput });
		setChatInput('');
	};

	return (
		<div className={classnames(classes.root, className)}>
			<div className={classes.fileGroup}>
				<div className={classes.fileStyle} style={{ cursor: 'pointer' }}>
					<UploadImage sendMessage={sendMessage} addMessage={addMessage} />
				</div>
			</div>
			{botSetting?.status ? (
				<div className={classes.fileGroup2}>
					<div className={classes.fileStyle} style={{ cursor: 'pointer' }}>
						<AutoConnectBot sendMessage={sendMessage} sendBotMessage={sendBotMessage} />
					</div>
				</div>
			) : (
				''
			)}

			<TextField
				inputRef={chartInputRef}
				className={classes.input}
				isValid
				multiline
				minRows={1}
				maxRows={1}
				onChange={textChangeHandler}
				keyPress={(e: any) => {
					if (e.key === 'Enter' && e.shiftKey) {
						// TODO: do nothing no remove this line
					} else if (e.key === 'Enter' && chatInput !== '') {
						submitHandler();
						e.preventDefault();
					} else if (e.key === 'Enter' && chatInput === '') {
						e.preventDefault();
					}
				}}
				value={chatInput}
				placeholder={t('enterPlaceholder')}
				type="text"
				InputProps={{
					endAdornment: (
						<InputAdornment position="end" className={classes.endAdornment}>
							<EmojiPicker onClick={onEmojiClick} />
						</InputAdornment>
					),
				}}
				onFocus={() => {}}
			/>
			<div className={classes.fileGroup}>
				<div className={classes.fileStyle} style={{ cursor: chatInput ? 'pointer' : 'defalut' }}>
					<IconSend id="send" disabled={!chatInput} onClick={submitHandler} />
				</div>
			</div>
		</div>
	);
};

export default ChatInput;
