/* eslint-disable react/no-array-index-key */
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Fragment } from 'react';
import { color } from '../common/themes';

const useStyles = makeStyles(
	() => ({
		row: {},
		container: {
			overflowX: 'auto',
			paddingBottom: 8,
		},
		content: {
			display: 'inline-flex',
			padding: '5px 12px',
			alignItems: 'center',
			borderRadius: '16px',
			border: `1px solid ${color.$2}`,
			color: color.$2,
			fontSize: 15,
			marginRight: 8,
			cursor: 'pointer',
			marginTop: 6,
			'&:hover': {
				backgroundColor: color.$23,
			},
		},
	}),
	{
		name: 'QuickReplyPreview',
	}
);

function QuickReplyPreview({ isPaddLeft, quickReply, submitMessage, isFlexEnd, isMarginBottom, isMarginTop }: any) {
	const classes = useStyles();
	const flexEndStyle = isFlexEnd && {
		display: 'flex',
		justifyContent: 'flex-end',
	};

	const onBtnClick = (data: any) => {
		switch (data?.action) {
			case 'postback':
				submitMessage({ content: data?.text, type: 'postback', data: data?.data });
				break;
			default:
		}
	};

	return (
		<div
			style={{
				paddingLeft: isPaddLeft ? 57 : 0,
				...flexEndStyle,
			}}
		>
			<div
				className={classes.container}
				style={{
					marginBottom: isMarginBottom ? 12 : 0,
					marginTop: isMarginTop ? 6 : 0,
				}}
			>
				<div className={classes.row}>
					{quickReply
						.filter((d: any) => d?.label)
						.map((d: any, i: number) => (
							<Fragment key={`quickReply${i}`}>
								<div className={classes.content} onClick={() => onBtnClick(d)}>
									{d.label}
								</div>
							</Fragment>
						))}
				</div>
			</div>
		</div>
	);
}

export default QuickReplyPreview;
