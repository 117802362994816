import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { color } from '../common/themes';

const useStyles = makeStyles(
	() => ({
		customerFormTitle: {
			fontSize: 15,
			color: color.$13,
			marginBottom: 24,
		},
		customerForm: {
			backgroundColor: color.$7,
			wordWrap: 'break-word',
			padding: '16px',
			width: 204,
			boxShadow: '0 8px 36px 0 rgba(0, 0, 0, 0.06)',
			borderRadius: 12,
		},
		customerFormRow: {
			marginBottom: 12,
		},
		customerFormButton: {
			width: '100%',
		},
	}),
	{
		name: 'CustomerFormDone',
	}
);

const CustomerFormDone = ({ data, submitCustomerFormMessage }: any) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const submitForm = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.contentType = 'application/x-customer-form';
		newData.data.displayName = '';
		newData.data.email = '';
		newData.data.phone = '';

		submitCustomerFormMessage(newData);
	};

	return (
		<div className={classes.customerForm}>
			<div className={classes.customerFormTitle}>
				{t('formDone', { displayName: data.data.displayName, email: data.data.email || data.data.phone })}
			</div>
			<Button
				topic="primaryGreen"
				onClick={() => {
					submitForm();
				}}
				className={classes.customerFormButton}
			>
				{t('reEdit')}
			</Button>
		</div>
	);
};

export default CustomerFormDone;
