import { useEffect, useRef, useState } from 'react';

import { getMessageObject } from '../common/Util';
import LivechatService from '../services/LivechatService';
import IconImage from './IconImage';

const UploadImage = ({ sendMessage, addMessage }: any) => {
	const UPLOAD_IMAGE_FILE_SIZE_LIMIT_BYTES = 10000000;
	const fileRef: any = useRef(null);

	const [isError, setIsError] = useState(false);

	const getAcceptFile = () => {
		const res = '.png,.jpg,.jpeg';
		return res;
	};

	const validFile = (eventFile: any) => {
		if (!encodeURIComponent(eventFile.name).match(/^[_a-zA-Z0-9%][a-zA-Z0-9@%._-]*$/)) {
			return false;
		}

		if (eventFile.type.startsWith('image/')) {
			if (!/^image\/(jpeg|jpg|png|gif)/.test(eventFile.type)) {
				return false;
			}
			if (eventFile.size > UPLOAD_IMAGE_FILE_SIZE_LIMIT_BYTES) {
				return false;
			}
		}

		return true;
	};

	const compress = (img: any, q = 0.8) => {
		const canvas = document.createElement('canvas');
		canvas.width = img.width;
		canvas.height = img.height;
		const ctx: any = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0, img.width, img.height);

		return new Promise(resolve => {
			canvas.toBlob(resolve, 'image/jpeg', q);
			canvas.remove();
		});
	};

	const handleGetCompressImage = (eventFile: any) => {
		return new Promise(resolve => {
			if (/^image\/(jpeg|jpg)/.test(eventFile.type)) {
				const img = new Image();
				img.src = URL.createObjectURL(eventFile);
				img.onload = async () => {
					const blob: any = await compress(img);
					if (blob.size < eventFile.size) {
						const newFile = new File([blob], eventFile.name, {
							type: eventFile.type,
						});
						resolve(newFile);
					} else {
						resolve(eventFile);
					}
				};
			} else {
				resolve(eventFile);
			}
		});
	};

	const upload = async (eventFile: any, eventType: any, duration: any = 0) => {
		const twoYearFromNow = new Date();
		twoYearFromNow.setFullYear(twoYearFromNow.getFullYear() + 2);

		const fileToSend = {
			name: eventFile.name,
			contentType: eventFile.type,
			url: URL.createObjectURL(eventFile),
			duration,
			size: eventFile.size,
			expireAt: twoYearFromNow.toISOString(),
		};

		try {
			const messageObject = getMessageObject(
				{
					contentType: 'image/jpeg',
					name: fileToSend.name,
					size: fileToSend.size,
					url: fileToSend.url,
					duration,
					expireAt: fileToSend.expireAt,
				},
				'application/x-image'
			);

			addMessage(messageObject);

			const res = await LivechatService.uploadServer(eventFile);
			sendMessage({ ...messageObject, data: { ...messageObject.data, url: res.url } });
			fileRef.current.value = null;
		} catch {
			setIsError(true);
		}
	};

	const handleUploadImage = async (eventFile: any) => {
		if (validFile(eventFile)) {
			const newFile = await handleGetCompressImage(eventFile);
			upload(newFile, 'image');
		} else {
			setIsError(true);
		}
	};

	const fileUpload = (item: any) => {
		const reader = new FileReader();
		const eventFile = item;

		reader.onerror = () => {
			setIsError(true);
		};

		reader.onload = () => {
			if (eventFile) {
				if (eventFile.type.includes('image')) {
					handleUploadImage(eventFile);
				}
			}
		};

		reader.readAsDataURL(eventFile);
	};

	const handleUploadChange = (e: any) => {
		e.preventDefault();
		setIsError(false);
		fileUpload(e.target.files[0]);
	};

	useEffect(() => {
		if (isError) {
			const messageObject = getMessageObject(
				{
					error: 'errorImage',
				},
				'application/x-image'
			);
			setIsError(false);
			addMessage(messageObject);
		}
	}, [addMessage, getMessageObject, isError]);

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label htmlFor="fileUpload" style={{ cursor: 'pointer' }}>
			<input
				name="fileUpload"
				id="fileUpload"
				type="file"
				style={{ display: 'none' }}
				accept={getAcceptFile()}
				onChange={e => handleUploadChange(e)}
				ref={fileRef}
			/>
			<IconImage id="image" />
		</label>
	);
};

export default UploadImage;
