import { useState, useRef } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import emojiArray from '../assets/emoji.json';
import IconEmoji from './IconEmoji';

const EmojiPicker = ({ onClick }: any) => {
	const iconRef: any = useRef(null);

	const [isOpen, setOpen] = useState(false);

	const onEmojiClick = (item: any) => {
		setOpen(false);
		onClick(item);
	};
	const onClickAway = () => {
		setOpen(false);
	};

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<div>
				<span onClick={() => setOpen(!isOpen)} style={{ display: 'inline-block' }} ref={iconRef}>
					<IconEmoji id="emoji" />
				</span>

				{isOpen && (
					<div
						style={{
							bottom: 46,
							right: 0,
							display: 'flex',
						}}
						className="emoji-container"
					>
						{emojiArray.map(item => (
							<div className="emoji-item" key={item} role="presentation" onClick={() => onEmojiClick(item)}>
								{item}
							</div>
						))}
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
};

export default EmojiPicker;
