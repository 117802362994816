import { nextAPI, axiosInstance } from '../common/axios';
import config from '../common/config';

export default class LivechatService {
	static getOrgData = async (orgId: any) => {
		const res = await nextAPI.get(`/live_chat/brand/${orgId}`);
		return res;
	};

	static getSettingData = async (orgId: any) => {
		const res: any = await axiosInstance.get(`${config.LIVECHAT_STYLE_URL}/${encodeURIComponent(orgId)}.json`);
		return res;
	};

	static getMessages = async (orgId: any, customerToken: any, skip: number = 0, limit: number = 16) => {
		const res: any = await nextAPI.get(`/live_chat/message/${orgId}/${customerToken}?skip=${skip}&limit=${limit}`);
		return res;
	};

	static submitCustomerForm = async (orgId: any, customerToken: any, name: string, email: string, phone: string) => {
		await nextAPI.put<any, any>(`/live_chat/customer/${orgId}/${customerToken}`, {
			displayName: name,
			email,
			phone,
		});
	};

	static generateToken = async (orgId: any) => {
		const res = await nextAPI.post<any, any>('/live_chat/generate_token', {
			encryptedOrgId: orgId,
		});
		return res;
	};

	static uploadServer = async (file: any) => {
		// get file url

		const response: any = await axiosInstance.get(`${config.UPLOAD_URL}/${encodeURIComponent(file.name)}`);

		// put file to server
		await axiosInstance.put(response.url, file, {
			headers: {
				'Content-Type': file.type,
			},
		});

		return { name: file.name, url: response.location };
	};

	static getBotSetting = async (orgId: any, customerToken: any) => {
		const res: any = await nextAPI.get(`/live_chat/bot_setting/${orgId}/${customerToken}`);
		return res;
	};
}
