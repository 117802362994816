import { useState } from 'react';
import { color } from '../common/themes';

const IconImage = ({ onClick = () => {}, className, id, iconColor = color.$12 }: any) => {
	const [isHover, setIsHover] = useState(false);

	return (
		<svg
			id={id}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			onMouseEnter={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setIsHover(false);
			}}
			onClick={onClick}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.65984 5.28049C9.65984 6.53997 10.7077 7.56098 12.0003 7.56098C13.2929 7.56098 14.3407 6.53997 14.3407 5.28049C14.3407 4.02101 13.2929 3 12.0003 3C10.7077 3 9.65984 4.02101 9.65984 5.28049ZM4.12793 10.2561C4.12793 8.99662 5.17577 7.97561 6.46836 7.97561H17.5322C18.8248 7.97561 19.8726 8.99662 19.8726 10.2561V17.7195C19.8726 18.979 18.8248 20 17.5322 20H6.46836C5.17577 20 4.12793 18.979 4.12793 17.7195V10.2561ZM6.46836 9.21951C5.88082 9.21951 5.40453 9.68361 5.40453 10.2561V17.7195C5.40453 18.292 5.88082 18.7561 6.46836 18.7561H17.5322C18.1197 18.7561 18.596 18.292 18.596 17.7195V10.2561C18.596 9.68361 18.1197 9.21951 17.5322 9.21951H6.46836ZM7.24612 12.228C7.24612 12.9736 7.85054 13.578 8.59612 13.578C9.34171 13.578 9.94612 12.9736 9.94612 12.228C9.94612 11.4825 9.34171 10.878 8.59612 10.878C7.85054 10.878 7.24612 11.4825 7.24612 12.228ZM15.4046 13.578C14.659 13.578 14.0546 12.9736 14.0546 12.228C14.0546 11.4825 14.659 10.878 15.4046 10.878C16.1502 10.878 16.7546 11.4825 16.7546 12.228C16.7546 12.9736 16.1502 13.578 15.4046 13.578Z"
				fill={isHover ? color.$1 : iconColor}
			/>
			<path
				d="M12.0004 6.31731C12.5879 6.31731 13.0642 5.85322 13.0642 5.28073C13.0642 4.70824 12.5879 4.24414 12.0004 4.24414C11.4128 4.24414 10.9365 4.70824 10.9365 5.28073C10.9365 5.85322 11.4128 6.31731 12.0004 6.31731Z"
				fill="white"
			/>
			<path
				d="M11.3613 7.00867C11.3613 6.66518 11.6471 6.38672 11.9996 6.38672C12.3521 6.38672 12.6379 6.66518 12.6379 7.00867V8.18252C12.6379 8.52601 12.3521 8.80447 11.9996 8.80447C11.6471 8.80447 11.3613 8.52601 11.3613 8.18252V7.00867Z"
				fill={isHover ? color.$1 : iconColor}
			/>
			<path
				d="M4.76596 10.8779C5.11848 10.8779 5.40426 11.1564 5.40426 11.4999C5.40426 11.8434 5.11848 12.1218 4.76596 12.1218C3.94341 12.1218 3.2766 12.7716 3.2766 13.5731C3.2766 14.3745 3.94341 15.0243 4.76596 15.0243C5.11848 15.0243 5.40426 15.3027 5.40426 15.6462C5.40426 15.9897 5.11848 16.2682 4.76596 16.2682C3.23836 16.2682 2 15.0615 2 13.5731C2 12.0846 3.23836 10.8779 4.76596 10.8779Z"
				fill={isHover ? color.$1 : iconColor}
			/>
			<path
				d="M19.234 16.2682C18.8815 16.2682 18.5957 15.9897 18.5957 15.6462C18.5957 15.3027 18.8815 15.0243 19.234 15.0243C20.0566 15.0243 20.7234 14.3745 20.7234 13.5731C20.7234 12.7716 20.0566 12.1218 19.234 12.1218C18.8815 12.1218 18.5957 11.8434 18.5957 11.4999C18.5957 11.1564 18.8815 10.8779 19.234 10.8779C20.7616 10.8779 22 12.0846 22 13.5731C22 15.0615 20.7616 16.2682 19.234 16.2682Z"
				fill={isHover ? color.$1 : iconColor}
			/>
			<path
				d="M9.68252 15.8126C9.58838 15.4816 9.78747 15.1389 10.1272 15.0472C10.4669 14.9554 10.8186 15.1494 10.9128 15.4804C11.0562 15.9848 11.3659 16.1984 11.9998 16.1984C12.6336 16.1984 12.9433 15.9848 13.0868 15.4804C13.1809 15.1494 13.5326 14.9554 13.8723 15.0472C14.2121 15.1389 14.4111 15.4816 14.317 15.8126C14.0154 16.8734 13.1903 17.4423 11.9998 17.4423C10.8092 17.4423 9.98418 16.8734 9.68252 15.8126Z"
				fill={isHover ? color.$1 : iconColor}
			/>
		</svg>
	);
};

export default IconImage;
