import { Component } from 'react';
import Input from './Input';

// eslint-disable-next-line react/prefer-stateless-function
class TextField extends Component<any> {
	render() {
		const {
			className,
			style,
			isValid,
			InputProps,
			disabled,
			enableError = false,
			minRows,
			keyPress,
			keyDown,
			min,
			inputRef,
			select,
			MenuItems,
			SelectProps,
			maxRows,
			multiline,
			wordCount,
			limit,
			topic = 'default',
			error,
			onChange,
			maxLength,
			...others
		}: any = this.props;
		return (
			<Input
				disabled={disabled}
				wordCount={wordCount}
				limit={limit}
				inputRef={inputRef}
				className={className}
				minRows={minRows}
				min={min}
				maxLength={maxLength}
				multiline={multiline}
				maxRows={maxRows}
				style={style}
				topic={topic}
				error={error}
				valid={isValid}
				InputProps={InputProps}
				onKeyPress={keyPress}
				onKeyDown={keyDown}
				select={select}
				MenuItems={MenuItems}
				SelectProps={SelectProps}
				enableError={enableError}
				onChange={onChange}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...others}
			/>
		);
	}
}

export default TextField;
