/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import icSuper8 from '../assets/images/png/ic_super8_24@2x.png';
import icAlertTriangle from '../assets/images/svg/ic-alert-triangle.svg';
import { getMessageObject, permitMessageContentType, signPayload } from '../common/Util';
import config from '../common/config';
import messageType from '../common/messageType';
import { color } from '../common/themes';
import LivechatService from '../services/LivechatService';
import BotTyping from './BotTyping';
import ChatInput from './ChatInput';
import Circular from './Circular';
import FileReviewer from './FileReviewer';
import IconBack from './IconBack';
import IconX from './IconX';
import ImageDisplay from './ImageDisplay';
import MessageContent from './MessageContent';

const useStyles = makeStyles(
	() => ({
		main: (props: any) => ({
			display: 'flex',
			flexDirection: 'column',
			borderRadius: props.isMobile ? 'none' : '12px',
			backgroundColor: color.$7,
			height: props.isMobile ? '100vh' : 580,
			width: props.isMobile ? '100vw' : '100%',
			maxHeight: props.isMobile ? 'none' : `calc(100vh - 110px - ${props?.styleData?.positionSettings?.bottom || 0}px)`,
		}),
		content: {
			display: 'flex',
			overflowY: 'auto',
			'-webkit-overflow-scrolling': 'touch',
			flexDirection: 'column-reverse',
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column-reverse',
			justifyContent: 'flex-end',
			borderLeft: `1px solid ${color.$8}`,
			borderRight: `1px solid ${color.$8}`,
		},
		me: {
			textAlign: 'right',
		},
		loading: {
			justifyContent: 'center',
			display: 'flex',
			alignItems: 'center',
			height: '100%',
		},
		header: (props: any) => ({
			position: 'relative',
			padding: 12,
			borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
			borderTopLeftRadius: props.isMobile ? 'none' : '12px',
			borderTopRightRadius: props.isMobile ? 'none' : '12px',
			minHeight: 51,
			display: 'flex',
			alignItems: 'center',
		}),
		displayName: {
			fontSize: '15px',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1',
		},
		x: {
			position: 'absolute',
			top: 12,
			right: 12,
			cursor: 'pointer',
		},
		left: {
			position: 'relative',
			bottom: 12,
			marginRight: 10,
			cursor: 'pointer',
		},
		fromMe: {
			display: 'flex',
			flexDirection: 'row-reverse',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			padding: '12px 16px',
		},
		fromOrg: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			padding: '12px 16px',
		},
		msgRow: {
			display: 'flex',
			alignItems: 'baseline',
			marginTop: 8,
		},
		datetime: {
			fontSize: '13px',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1.38',
			color: color.$10,
		},
		messageMe: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		messageCustomer: {
			// display: 'flex',
		},
		copyright: {
			fontSize: '13px',
			fontWeight: 500,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: '1.38',
			color: color.$12,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: '11px',
			'&:hover': {
				backgroundColor: 'rgba(224, 224, 224, 0.2)',
			},
		},
		error: {
			marginLeft: 6,
			fontSize: 13,
			color: color.$10,
		},
		errorRow: {
			display: 'flex',
		},
		headRow: {
			width: 224,
		},
		richMenu: {
			width: 'calc(100% - 2px)',
			display: 'flex',
			borderLeft: `1px solid ${color.$9}`,
			borderRight: `1px solid ${color.$9}`,
		},
		richMenuItem: {
			cursor: 'pointer',
			fontSize: 13,
			lineHeight: 1.4,
			color: color.$12,
			borderTop: `1px solid ${color.$9}`,
			padding: '10px 8px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			wordBreak: 'break-all',
			textAlign: 'center',
		},
		hr: {
			width: 1,
			backgroundColor: color.$9,
		},
	}),
	{
		name: 'Chat',
	}
);

const Chat: ForwardRefRenderFunction<any, any> = ({ setIsOpenChat, onClose, orgData, styleData }: any, forwardedRef) => {
	const isMobile = useSelector((store: any) => store.socketio.isMobile);

	const { t } = useTranslation();

	const classes = useStyles({ isMobile, styleData });
	const [messages, setMessages] = useState<any>([]);
	const [isLoading, setIsloading] = useState(false);
	const [isLazyLoad, setIsLazyLoad] = useState(false);
	const [lastMessageLength, setLastMessageLength] = useState(0);
	const [botTypingCount, setBotTypingCount]: any = useState(0);
	const [fileUrl, setFileUrl] = useState('');

	const messagesContainerRef: any = useRef(null);
	const messagesRef: any = useRef(null);
	const richMenuRef: any = useRef(null);

	messagesRef.current = messages;

	const orgId = useSelector((store: any) => store.socketio.orgId);

	const socketClient = useSelector((store: any) => store.socketio.client);
	const socketClientReady = useSelector((store: any) => store.socketio.client);

	const { livechatBackgroundColor, livechatTextColor, hiddenPoweredBy, richMenu } = styleData || {};
	const customerToken = useSelector((store: any) => store.socketio.customerToken);

	const [notifyMessage, setNotifyMessage] = useState<any>(null);

	const [botSetting, setBotSetting] = useState<any>({});

	const scrollEnd = () => {
		if (messagesContainerRef.current) {
			messagesContainerRef.current.scrollTop = -1;
		}

		setTimeout(() => {
			if (messagesContainerRef.current) {
				messagesContainerRef.current.scrollTop = 0;
			}
		}, 100);
	};

	const getMessage = async (limit = 16) => {
		const response: any = await LivechatService.getMessages(orgId, customerToken, messages.length, limit);

		setLastMessageLength(response.results.length);

		return response.results;
	};

	const getBotSettings = async () => {
		const response: any = await LivechatService.getBotSetting(orgId, customerToken);
		return response;
	};

	const addMessage = (messageObject: any) => {
		setMessages([messageObject, ...messagesRef.current]);
		scrollEnd();
	};

	useImperativeHandle(forwardedRef, () => ({
		notifyAddMessage(message: any) {
			setNotifyMessage(message);
		},
	}));

	useEffect(() => {
		if (!isLoading && notifyMessage) {
			const findAlreadyMessage = messages.find((d: any) => d.receiptId === notifyMessage.receiptId);
			if (!findAlreadyMessage) {
				setMessages([notifyMessage, ...messages]);
				scrollEnd();
			}
			setNotifyMessage(null);
		}
	}, [isLoading, notifyMessage]);

	const sendMessage = (messageObject: any, eventType: any = 'message') => {
		let data = JSON.stringify(messageObject);

		// sign the payload
		data = signPayload(data);

		socketClient.emit(eventType, data);
	};

	const submitMessage = (sendData: any, type = messageType.TEXT_PLAIN_TYPE) => {
		const messageObject = getMessageObject(sendData, type);
		addMessage(messageObject);
		sendMessage(messageObject);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsloading(true);
				const apiMessages = await getMessage();
				setMessages([...apiMessages, ...messages]);
				const setting = await getBotSettings();
				setBotSetting(setting);
				scrollEnd();
			} catch (e) {
				console.log(e);
			} finally {
				setIsloading(false);
			}
		};
		if (customerToken) {
			fetchData();
		}
	}, [customerToken]);

	const getMessageStyle = (item: any) => {
		const resStyle: any = {};

		if (item.error || item.isPending) {
			resStyle.opacity = 0.5;
		}

		return resStyle;
	};

	const messageList = messages
		? messages.sort((a: any, b: any) => {
				const dateA: any = new Date(a.createdAt);
				const dateB: any = new Date(b.createdAt);
				return dateB - dateA;
		  })
		: [];

	const handleReceiveMessage = (message: any) => {
		if (message.contentType === messageType.BOT_TYPING) {
			if (message.data.content === 'start') {
				setBotTypingCount(botTypingCount + 1);
			} else {
				setBotTypingCount(botTypingCount - 1);
			}
		} else {
			let isAdd = true;

			messages.map((d: any) => {
				if (d.receiptId && d.receiptId === (message?.receiptId || message?.data?.receiptId)) {
					delete d.isPending;
					d.objectId = message.objectId;
					d.createdAt = message.createdAt;
					isAdd = false;

					if (d.contentType === 'application/x-image') {
						d.data.url = message.data.url;
					}

					return d;
				}

				if (!message.createdAt) {
					message.createdAt = new Date().toISOString();
				}

				return d;
			});

			if (isAdd) {
				setMessages((dataMessages: any) => [...dataMessages, message]);
			} else {
				setMessages([...messages]);
			}
		}
	};

	const onReceiveMessage = (message: any) => {
		if (permitMessageContentType(message.contentType)) {
			handleReceiveMessage(message);
			scrollEnd();
		}
	};

	const queryMessagesOfConversationLazy = async () => {
		const limit = 12;
		if (lastMessageLength >= limit) {
			const apiMessages = await getMessage(limit);
			setMessages([...apiMessages, ...messages]);
		}
	};

	const handleScroll = async () => {
		if (
			!isLazyLoad &&
			messagesContainerRef.current.scrollHeight +
				messagesContainerRef.current.scrollTop -
				messagesContainerRef.current.clientHeight <
				10 &&
			messages &&
			messages.length > 0
		) {
			setIsLazyLoad(true);
			await queryMessagesOfConversationLazy();
			setIsLazyLoad(false);
		}
	};

	const onFileClick = (objectId: string, index: number = 0) => {
		const findFile = messages.find((d: any) => d.objectId === objectId);
		if (findFile) {
			if (findFile.contentType === messageType.IMAGE_SET) {
				setFileUrl(findFile?.data?.images?.[index]?.url);
			} else {
				setFileUrl(findFile.data.url);
			}
		}
	};

	const onEchoMessage = (message: any) => {
		if (message?.objectId) {
			sendMessage({ messageObjectId: message.objectId }, 'messageAck');
		}
	};

	const submitCustomerFormMessage = (data: any) => {
		setMessages([...messages.filter((d: any) => d.objectId !== data.objectId), data]);
	};

	const sendBotMessage = async () => {
		const setting = await getBotSettings();
		if (setting.status === 1) {
			// 正常發送bot的keyword
			const messageObject = getMessageObject({ content: botSetting.keyword }, messageType.TEXT_PLAIN_TYPE);
			sendMessage(messageObject);
		}
	};

	const richMenuComponent = useMemo(() => {
		const component: any[] | null = [];
		if (richMenu?.enabled) {
			richMenu?.buttons.forEach((menu: any, index: number) => {
				component.push(
					<div
						className={classes.richMenuItem}
						key={`buttons-${index}`}
						style={{
							width: `calc((100% - ${(richMenu?.buttons.length || 1) - 1}px) / ${richMenu?.buttons.length || 1})`,
						}}
						onClick={() => {
							switch (menu.type) {
								case 'postback':
									submitMessage({ content: menu?.data, type: 'postback', data: { t: 'richmenu', tg: menu?.tags.join('|') } });
									break;
								case 'url':
									window.open(menu.data);
									break;
								default:
							}
						}}
					>
						{menu?.title}
					</div>
				);

				if (richMenu?.buttons.length - 1 !== index) {
					component.push(<div key={`buttons-hr-${index}`} className={classes.hr} />);
				}
			});
			return component;
		}
		return null;
	}, [richMenu]);

	useEffect(() => {
		if (socketClient && socketClientReady) {
			socketClient.on('messageEcho', onReceiveMessage);
			socketClient.on('messageReceived', onReceiveMessage);
			socketClient.on('messageReceived', onEchoMessage);
		}
		return () => {
			if (socketClient && socketClientReady) {
				socketClient.off('messageEcho', onReceiveMessage);
				socketClient.off('messageReceived', onReceiveMessage);
				socketClient.off('messageReceived', onEchoMessage);
			}
		};
	}, [onReceiveMessage, onEchoMessage, socketClient, socketClientReady]);

	useEffect(() => {
		setMessages([]);
	}, [customerToken]);

	return (
		<div className={classes.main}>
			<div className={classes.header} style={{ backgroundColor: livechatBackgroundColor }}>
				<IconBack id="left" onClick={() => setIsOpenChat(false)} className={classes.left} color={color.$7} />
				<IconX id="x" onClick={() => onClose()} className={classes.x} color={color.$7} hoverColor={color.$7} />
				<ImageDisplay
					style={{
						borderRadius: '50%',
						marginRight: 12,
					}}
					src={orgData.icon}
					width={44}
					height={44}
				/>
				<div className={classes.headRow}>
					<div
						className={classes.displayName}
						style={{
							color: livechatTextColor,
						}}
					>
						{orgData.displayName}
					</div>
				</div>
			</div>
			<div
				className={classes.contentContainer}
				style={{ height: `calc(100% - 153px - ${richMenuRef?.current?.offsetHeight || 0}px)` }}
			>
				{isLoading ? (
					<div className={classes.loading}>
						<Circular topic="grey" variant="indeterminate" size={20} />
					</div>
				) : (
					<div className={classes.content} ref={messagesContainerRef} onScroll={handleScroll}>
						{!hiddenPoweredBy && (
							<div className={classes.copyright}>
								<ImageDisplay
									style={{
										borderRadius: '50%',
										marginRight: 4,
									}}
									src={icSuper8}
									width={24}
									height={24}
								/>
								<a style={{ color: 'unset' }} href={config.SUPER8_URL} target="_blank" rel="noreferrer">
									Powered by Super 8
								</a>
							</div>
						)}
						{botTypingCount > 0 && <BotTyping orgData={orgData} isMobile={isMobile} />}
						{messageList.map((d: any, index: number) => {
							const fromMe = d.senderType === 'Customer' ? 'from-me' : '';

							return (
								<div className={`${fromMe ? classes.fromMe : classes.fromOrg} `} key={d.receiptId || d.objectId}>
									{!fromMe && (
										<ImageDisplay
											alt={d.receiptId || d.objectId}
											style={{
												borderRadius: '50%',
											}}
											src={orgData.icon}
											width={44}
											height={44}
										/>
									)}
									<div style={{ margin: '0px 12px', width: 'calc(100% - 88px)' }}>
										<div style={getMessageStyle(d)} className={!fromMe ? classes.messageCustomer : classes.messageMe}>
											<MessageContent
												onFileClick={onFileClick}
												fromMe={fromMe}
												data={d}
												submitCustomerFormMessage={submitCustomerFormMessage}
												submitMessage={submitMessage}
												isShowQuickReply={index === 0}
											/>
										</div>
										<div
											className={classes.msgRow}
											style={
												fromMe
													? {
															flexDirection: 'row-reverse',
													  }
													: {}
											}
										>
											{d.error || d.data.error ? (
												<div className={classes.errorRow}>
													<img src={icAlertTriangle} width={20} height={20} alt="alert" />
													<span className={classes.error}> {t('sendError')}</span>
												</div>
											) : (
												<span className={classes.datetime}>{moment(new Date(d.createdAt)).format('YYYY-MM-DD HH:mm')}</span>
											)}
										</div>
									</div>
								</div>
							);
						})}
						{isLazyLoad && (
							<div className={classes.loading}>
								<Circular topic="grey" variant="indeterminate" size={20} />
							</div>
						)}
					</div>
				)}
			</div>
			{richMenu?.enabled && (
				<div ref={richMenuRef} className={classes.richMenu}>
					{richMenuComponent}
				</div>
			)}
			<FileReviewer
				url={fileUrl}
				onCloseclick={() => {
					setFileUrl('');
				}}
			/>
			<ChatInput
				botSetting={botSetting}
				sendBotMessage={sendBotMessage}
				submitMessage={submitMessage}
				addMessage={addMessage}
				sendMessage={sendMessage}
			/>
		</div>
	);
};

export default forwardRef(Chat);
