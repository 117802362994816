const common: any = {
	LOCAL_STORAGE_CUSTOMER_TOKEN: 'super8_customer_token',
	DEFAULT_LIVECHAT_LOGO_EXAMPLE_FILE: 'https://assets.no8.io/live_chat/default_logo.png',
	SUPER8_URL: 'https://www.no8.io/',
};

const local: any = {
	SOCKET_SERVER_URL: 'ws://localhost:8082',
	NEXT_API_URL: 'http://localhost:8081',
	UPLOAD_URL: 'https://t.no8.io/uploader/files',
};

const stag: any = {
	SOCKET_SERVER_URL: 'wss://stage-ws.no8.io',
	NEXT_API_URL: 'https://stage-api-next.no8.io',
	UPLOAD_URL: 'https://t.no8.io/uploader/files',
	LIVECHAT_STYLE_URL: 'https://assets.no8.io/stage/live_chat/style',
};

const prod: any = {
	SOCKET_SERVER_URL: 'wss://ws.no8.io',
	NEXT_API_URL: 'https://api-next.no8.io',
	UPLOAD_URL: 'https://n.no8.io/uploader/files',
	LIVECHAT_STYLE_URL: 'https://assets.no8.io/prod/live_chat/style',
};

let config;
switch (process.env.REACT_APP_STAGE) {
	case 'production':
		config = { ...common, ...prod };
		break;
	case 'local':
		config = { ...common, ...local };
		break;
	default:
		config = { ...common, ...stag };
}
const exportConfig = config;
export default exportConfig;
