/* eslint-disable react/no-array-index-key */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import hexRgba from 'hex-rgba';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import defaultPNG from '../assets/images/png/img-default-tamplate-img@3x.png';
import MESSAGE_TYPE from '../common/messageType';
import { color } from '../common/themes';

import btnDownload from '../assets/images/svg/btn-download.svg';
import icMap from '../assets/images/svg/ic-map-pin-20.svg';
import imgDoc from '../assets/images/svg/img-file-doc.svg';

import CustomerForm from './CustomerForm';
import CustomerFormDone from './CustomerFormDone';
import QuickReplyPreview from './QuickReplyPreview';

import { downloadImage, showText } from '../common/Util';

const useStyles = makeStyles(
	() => ({
		text: {
			borderRadius: 12,
			backgroundColor: color.$8,
			fontSize: 15,
			color: color.$13,
			padding: '8px 12px',
			maxWidth: 188,
			wordWrap: 'break-word',
			wordBreak: 'break-word',
			'& .textMessageLink': {
				color: color.$13,
				textDecoration: 'underline',
				'&:hover': {
					opacity: 0.6,
				},
			},
		},
		textMe: {
			borderRadius: 12,
			backgroundColor: color.$13,
			fontSize: 15,
			color: color.$7,
			padding: '8px 12px',
			maxWidth: 188,
			wordWrap: 'break-word',
			wordBreak: 'break-word',
			'& .textMessageLink': {
				color: color.$7,
				textDecoration: 'underline',
				'&:hover': {
					opacity: 0.6,
				},
			},
		},
		addressTitleContain: {
			display: 'flex',
			marginBottom: 6,
			alignItems: 'center',
		},
		addressTitle: {
			margin: 0,
			width: 364,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			marginLeft: 6,
			fontWeight: 500,
			color: color.$3,
		},
		address: {
			borderRadius: 12,
			backgroundColor: color.$8,
			fontSize: 13,
			color: color.$12,
			padding: '8px 12px',
			maxWidth: 390,
			wordWrap: 'break-word',
			wordBreak: 'break-word',
		},
		addressMe: {
			borderRadius: 12,
			backgroundColor: color.$12,
			fontSize: 13,
			color: color.$7,
			padding: '8px 12px',
			maxWidth: 390,
			wordWrap: 'break-word',
			wordBreak: 'break-word',
		},
		audio: {
			'& > audio': {
				borderRadius: 12,
				width: 204,
				height: 58,
			},
		},
		video: {
			'& > video': {
				borderRadius: 12,
				maxWidth: 204,
				maxHeight: 160,
			},
		},
		image: {
			maxWidth: 204,
			maxHeight: 160,
			borderRadius: 16,
		},
		imageMap: {
			width: 204,
			borderRadius: 16,
			boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.11), 0 0 10px 0 rgba(0, 0, 0, 0.06)',
		},
		templateBlock: {
			marginBottom: 8,
		},
		templateContent: (porps: any) => ({
			width: 204,
			height: '100%',
			borderRadius: 12,
			border: `1px solid ${color.$8}`,
			backgroundColor: color.$7,
			display: porps.isCard ? 'flex' : 'block',
			flexDirection: 'column',
		}),
		'templateContent--isImage': {},
		templateImage: {
			objectFit: 'cover',
			display: 'block',
			width: 204,
			borderRadius: '12px 12px 0 0',
			margin: 0,
		},
		'templateImage--type-url': {
			width: 204,
			height: 204,
		},
		'templateImage--isImage': {
			borderRadius: 12,
		},
		templateTitle: {
			color: color.$3,
			fontSize: 13,
			padding: '12px 12px 4px 12px',
			wordWrap: 'break-word',
		},
		templateSubTitle: porps => ({
			padding: porps.isCard ? '0 12px 12px 12px' : 16,
			color: color.$11,
			fontSize: 13,
			lineHeight: 'normal',
			flex: porps.isCard ? 1 : 'none',
			wordWrap: 'break-word',
			whiteSpace: 'pre-wrap',
		}),
		btn: props => ({
			color: color.$2,
			fontSize: 13,
			lineHeight: 1,
			padding: '12px 16px',
			width: props.isCard ? '' : '34%',
			display: props.isCard ? '' : 'inline-block',
			textAlign: 'center',
			verticalAlign: 'top',
			borderTop: `1px solid ${hexRgba(color.$9, 60)}`,
			wordWrap: 'break-word',
			cursor: 'pointer',
		}),
		templateImageCard: {
			display: 'flex',
			overflowX: 'auto',
			boxShadow: '0 8px 36px 0 rgba(0, 0, 0, 0.06)',
			backgroundColor: color.$7,
			marginBottom: 12,
			'& > div + div': {
				marginLeft: 8,
			},
		},
		card: {
			display: 'flex',
			overflowX: 'auto',
			boxShadow: '0 8px 36px 0 rgba(0, 0, 0, 0.06)',
			marginBottom: 12,
			'& > div + div': {
				marginLeft: 8,
			},
		},
		messageBotCards: {
			display: 'flex',
			overflowX: 'auto',
			marginBottom: 12,
			'& > div + div': {
				marginLeft: 8,
			},
		},
		confirm: {
			marginBottom: 12,
		},
		file: {
			display: 'flex',
			flexDirection: 'column',
		},
		fileTitle: {
			marginBottom: '8px',
			padding: '8px 12px',
			borderRadius: '12px',
			color: color.$7,
			backgroundColor: color.$12,
			maxWidth: 188,
			wordWrap: 'break-word',
			wordBreak: 'break-word',
		},
		fileContain: {
			position: 'relative',
			display: 'inline-block',
		},
		fileIcon: {
			position: 'absolute',
			height: '100%',
			top: 0,
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			justifyContent: 'flex-end',
			'& > img': {
				display: 'none',
			},
			'&:hover > img': {
				display: 'block !important',
			},
		},
		fileImg: {
			objectFit: 'contain',
			backgroundColor: 'transparent',
			borderRadius: 10,
		},
		btnDownload: {
			borderRadius: 12,
			marginRight: 8,
			marginTop: 8,
		},
		share: {
			backgroundColor: color.$10,
			width: 4,
			marginRight: 8,
			marginBottom: 6,
		},
		delete: {
			fontSize: 13,
			color: color.$12,
			padding: '8px 12px',
			borderRadius: 12,
			border: `solid 1px ${color.$9}`,
			backgroundColor: color.$7,
		},

		richvideo: {
			width: 414,
			borderRadius: 12,
		},
		customerFormTitle: {
			fontSize: 15,
			color: color.$13,
			marginBottom: 24,
		},
		customerForm: {
			backgroundColor: color.$7,
			wordWrap: 'break-word',
			padding: '16px',
			width: 204,
			boxShadow: '0 8px 36px 0 rgba(0, 0, 0, 0.06)',
			borderRadius: 12,
		},
		customerFormRow: {
			marginBottom: 12,
		},
		customerFormButton: {
			width: '100%',
		},
		imageError: {
			width: 232,
			borderRadius: 12,
			backgroundColor: color.$12,
			color: color.$7,
			display: 'flex',
			padding: '16px',
			fontSize: 15,
		},
	}),
	{
		name: 'MessageContent',
	}
);

function Template({ type, imageType, imageUrl, subTitle, title, buttons, submitMessage }: any) {
	const isCard = type === 'card';
	const isImage = type === 'image';
	const classes = useStyles({ isCard });
	const [bgSrc, setBgSrc] = useState(defaultPNG);

	useEffect(() => {
		if (imageUrl) {
			const bgImg = new Image();
			bgImg.onload = () => {
				setBgSrc(bgImg.src);
			};
			bgImg.src = imageUrl;
		}
	}, [imageUrl]);

	const onBtnClick = (btn: any) => {
		switch (btn.type) {
			case 'postback':
				submitMessage({
					content: btn.data,
					type: 'postback',
					data: { t: btn.t || 'ta', u: btn.u, f: btn.f, ts: btn.ts, tg: btn?.tags?.join('|') },
				});
				break;
			case 'url':
				window.open(btn.data);
				break;
			default:
		}
	};

	const btnContent = () =>
		(buttons || []).map((btn: any) => {
			const { title: btnTitle } = btn;
			return (
				<div key={`${btnTitle}-${uuidv4()}`} onClick={() => onBtnClick(btn)} className={classes.btn}>
					{btnTitle}
				</div>
			);
		});

	return (
		<div className={classes.templateBlock}>
			<div
				className={classnames(classes.templateContent, {
					[classes['templateContent--isImage']]: isImage,
				})}
			>
				{(isCard || isImage) && (
					<>
						<img
							className={classnames(classes.templateImage, {
								[classes['templateImage--type-url']]: imageType === 'url',
								[classes['templateImage--isImage']]: isImage,
							})}
							src={bgSrc}
							alt={title}
						/>
						{isCard && <div className={classes.templateTitle}>{title}</div>}
					</>
				)}
				{!isImage && (
					<>
						<div className={classes.templateSubTitle}>{subTitle}</div>
						{btnContent()}
					</>
				)}
			</div>
		</div>
	);
}

function MessageContent({
	data,
	onFileClick = () => {},
	fromMe = '',
	isBoxShadow = true,
	submitCustomerFormMessage,
	submitMessage,
	isPreview,
	isShowQuickReply = true,
}: any) {
	const classes = useStyles({ isCard: false });
	const { t } = useTranslation();

	const [imgHeight, setImgHeight] = useState<any>(160);

	const quickReply = data?.data?.quickReply;

	const handleImageLoaded = () => {
		setImgHeight('initial');
	};

	const renderContent = () => {
		const { contentType, data: msgData, objectId, receiptId } = data;

		switch (contentType) {
			case MESSAGE_TYPE.TEXT_PLAIN_TYPE:
				return (
					msgData?.content && (
						<div
							key={`template-x-text-${receiptId || objectId}`}
							className={fromMe ? classes.textMe : classes.text}
							dangerouslySetInnerHTML={{
								__html: showText(msgData.content),
							}}
						/>
					)
				);

			case MESSAGE_TYPE.LOCATION_TYPE:
				return (
					msgData?.address && (
						<div key={`template-x-location-${receiptId || objectId}`} className={fromMe ? classes.addressMe : classes.address}>
							<div className={classes.addressTitleContain}>
								<img alt={`${msgData.address}`} src={icMap} width={20} height={20} />
								{msgData.title && (
									<>
										<span className={classes.addressTitle}>{msgData.title}</span>
										<br />
									</>
								)}
							</div>
							{msgData?.address}
						</div>
					)
				);
			case MESSAGE_TYPE.AUDIO_TYPE:
				return (
					msgData?.url && (
						<div
							key={`template-x-audio-${receiptId || objectId}`}
							className={classes.audio}
							dangerouslySetInnerHTML={{
								__html: ` <audio src=${msgData.url} controls>
              <track kind="captions" />
             </audio>`,
							}}
						/>
					)
				);

			case MESSAGE_TYPE.IMAGE_SET:
				return msgData.error ? (
					<div className={classes.imageError}>{t('imageError')}</div>
				) : (
					<div key={`template-x-image-set-${receiptId || objectId}`}>
						{msgData?.images?.map((image: any, i: number) => {
							return (
								<div
									key={`image-${image?.name}-${i}`}
									className={classes.fileContain}
									onClick={e => {
										e.stopPropagation();
										onFileClick(objectId, i);
									}}
									style={{ cursor: 'pointer' }}
								>
									<img
										className={classes.image}
										src={image.url}
										alt={`${image.name || ' '}`}
										onLoad={handleImageLoaded}
										style={{ height: imgHeight }}
									/>
									<div className={classes.fileIcon}>
										<img
											onClick={e => {
												e.stopPropagation();
												downloadImage(image.url);
											}}
											alt={`${image.name}`}
											className={classes.btnDownload}
											src={btnDownload}
											width={24}
											height={24}
										/>
									</div>
								</div>
							);
						})}
					</div>
				);

			case MESSAGE_TYPE.IMAGE_TYPE:
				return msgData.error ? (
					<div className={classes.imageError}>{t('imageError')}</div>
				) : (
					msgData.url && (
						<div key={`template-x-image-${receiptId || objectId}`}>
							<div
								className={classes.fileContain}
								onClick={e => {
									e.stopPropagation();
									onFileClick(objectId);
								}}
								style={{ cursor: 'pointer' }}
							>
								<img
									className={classes.image}
									src={msgData.url}
									alt={`${msgData.name || ' '}`}
									onLoad={handleImageLoaded}
									style={{ height: imgHeight }}
								/>

								<div className={classes.fileIcon}>
									<img
										onClick={e => {
											e.stopPropagation();
											downloadImage(msgData.url);
										}}
										alt={`${msgData.name}`}
										className={classes.btnDownload}
										src={btnDownload}
										width={24}
										height={24}
									/>
								</div>
							</div>
						</div>
					)
				);

			case MESSAGE_TYPE.VIDEO_TYPE:
				return (
					msgData?.url && (
						<div
							key={`template-x-video-${receiptId || objectId}`}
							className={classes.video}
							dangerouslySetInnerHTML={{
								__html: `<video src=${msgData.url}#t=0.5 controls preload="metadata">
              <track kind="captions" />
              </video>`,
							}}
						/>
					)
				);

			case MESSAGE_TYPE.FILE_TYPE:
				return (
					<div key={`template-x-file-${receiptId || objectId}`} className={classes.file}>
						{msgData.name && <div className={classes.fileTitle}>{msgData.name}</div>}
						<div className={classes.fileContain}>
							<img alt={`${msgData.name}`} className={classes.fileImg} src={imgDoc} width={120} height={120} />
							<div className={classes.fileIcon} style={{ width: 120 }}>
								<img
									onClick={e => {
										e.stopPropagation();
										window.open(msgData.url);
									}}
									style={{ cursor: 'pointer' }}
									alt={`${msgData.name}`}
									className={classes.btnDownload}
									src={btnDownload}
									width={24}
									height={24}
								/>
							</div>
						</div>
					</div>
				);
			case MESSAGE_TYPE.TEMPLATE_TYPE:
				const { templateType, content } = msgData;
				let { elements } = msgData;
				if (templateType === 'button') {
					elements = [
						{
							buttons: msgData.buttons,
							title: msgData.text,
						},
					];
				}
				switch (templateType) {
					case 'button':
					case 'confirm':
						return (
							<div key={`template-x-confirm-card-${receiptId || objectId}`} className={classes.confirm}>
								{Array.isArray(elements) &&
									elements.map((elm, i) => {
										const { buttons, title } = elm;
										return (
											<Template
												key={`template-confirm-${receiptId || objectId}-${i}`}
												type={templateType}
												subTitle={title}
												buttons={buttons}
												submitMessage={submitMessage}
											/>
										);
									})}
							</div>
						);
					case 'card':
						return (
							<div
								key={`template-x-optional-card-${receiptId || objectId}`}
								className={isBoxShadow ? classes.card : classes.messageBotCards}
							>
								{Array.isArray(elements) &&
									elements.map((elm, i) => {
										const { buttons, title, subtitle, imageType, imageUrl } = elm;
										if (isPreview && i !== 0) {
											return null;
										}
										return (
											<Template
												key={`template-card-${receiptId || objectId}-${i}`}
												type={templateType}
												imageType={imageType}
												imageUrl={imageUrl}
												title={title}
												subTitle={subtitle}
												buttons={buttons}
												isBoxShadow={isBoxShadow}
												submitMessage={submitMessage}
											/>
										);
									})}
							</div>
						);
					case 'image':
						return (
							<div key={`template-x-image-card-${receiptId || objectId}`} className={classes.templateImageCard}>
								{Array.isArray(elements) &&
									elements.map((elm, i) => {
										const { imageType, imageUrl, buttons } = elm;
										return (
											<Template
												key={`template-image-${receiptId || objectId}-${i}`}
												type={templateType}
												imageType={imageType}
												imageUrl={imageUrl}
												buttons={buttons}
												isPreview={isPreview}
											/>
										);
									})}
							</div>
						);
					case 'imagemap':
						return elements.map((elm: any) => {
							return (
								<div key={`template-x-imagemap-${receiptId || objectId}`}>
									<img className={classes.imageMap} src={elm.imageUrl} alt={`${elm.title}`} />
								</div>
							);
						});
					case 'text':
						return (
							<div
								key={`template-x-text-${receiptId || objectId}`}
								className={fromMe ? classes.textMe : classes.text}
								dangerouslySetInnerHTML={{
									__html: showText(content),
								}}
							/>
						);

					default:
						return null;
				}
			case MESSAGE_TYPE.X_CUSTOMER_FORM:
				return <CustomerForm data={data} submitCustomerFormMessage={submitCustomerFormMessage} />;
			case MESSAGE_TYPE.X_CUSTOMER_FORM_DONE:
				return <CustomerFormDone data={data} submitCustomerFormMessage={submitCustomerFormMessage} />;
			default:
				return null;
		}
	};

	return (
		<>
			{renderContent()}
			{isShowQuickReply && quickReply && <QuickReplyPreview isMarginTop quickReply={quickReply} submitMessage={submitMessage} />}
		</>
	);
}

export default MessageContent;
