import { useRef, useEffect } from 'react';
import { Portal } from 'react-portal';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { color } from '../common/themes';
import IconX from './IconX';

const useStyles = makeStyles(
	() => ({
		root: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			top: '0px',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			left: '0px',
			zIndex: 9999,
		},
		titleSection: {
			marginTop: 16,
			color: color.$7,
		},
		img: {
			width: '70vw',
			maxHeight: '70vh',
			objectFit: 'contain',
		},
		closeStyle: {
			position: 'absolute',
			top: 12,
			right: 12,
			cursor: 'pointer',
		},
		leftStyle: {
			position: 'absolute',
			top: '50%',
			left: 32,
			cursor: 'pointer',
		},
		rightStyle: {
			position: 'absolute',
			top: '50%',
			right: 32,
			cursor: 'pointer',
		},

		center: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			flexDirection: 'column',
		},
	}),
	{
		name: 'FileReviewer',
	}
);

const FileReviewer = ({ onCloseclick, url }: any) => {
	const classes = useStyles();
	const refContainer: any = useRef();
	const refImage: any = useRef();
	const isMobile = useSelector((store: any) => store.socketio.isMobile);

	const clickOutSide = (e: any) => {
		if (refImage.current && !refImage.current.contains(e.target)) {
			onCloseclick();
		}
	};

	const handleKeyDown = (e: any) => {
		switch (e.key) {
			case 'Escape':
				onCloseclick();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (url && !isMobile) {
			document.addEventListener('keydown', handleKeyDown);
			window.parent.postMessage({ target: 'eightChat', command: 'openImage' }, '*');
		}
		return () => {
			if (url && !isMobile) {
				document.removeEventListener('keydown', handleKeyDown);
				window.parent.postMessage({ target: 'eightChat', command: 'closeImage' }, '*');
			}
		};
	}, [handleKeyDown, url, isMobile]);

	const renderFile = () => {
		return <img ref={refImage} src={url} className={classes.img} alt="images" />;
	};
	return (
		<Portal>
			{url && (
				<div className={classes.root} onClick={clickOutSide}>
					<IconX id="x" onClick={onCloseclick} className={classes.closeStyle} color={color.$7} hoverColor={color.$7} />

					<div className={classes.center} ref={refContainer}>
						{renderFile()}
					</div>
				</div>
			)}
		</Portal>
	);
};

export default FileReviewer;
